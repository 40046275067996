import React, { useState } from 'react'
import { Col, Form, InputGroup, Row } from 'react-bootstrap'
import { Button, Typography } from '../../../../../components'
import { useDispatch } from 'react-redux'
import { actionChangePassword } from '../../../../../actions/Settings'
import { SnackMessages } from '../../../../../components/Toast'
import { EyeCloseIcon, EyeOpenIcon } from '../../../../../components/SVGIcons'
import "./index.scss"

const ChangePasswordModal = ({ handleCloseModal }) => {
    const dispatch = useDispatch()
    const { showErrorMessage, showSuccessMessage } = SnackMessages()
    const [isPasswordVIsible, setPasswordVisible] = useState(false)
    const [isConfirmPasswordVIsible, setConfirmPasswordVisible] = useState(false)
    const [payload, setPayload] = useState({ current_password: "", new_password: "", confirm_password: "" })
    const [errorMessage, setErrorMessage] = useState({ new_password: "", confirm_password: "" })
    const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    const checkValidation = () => {
        if (payload?.new_password?.length < 8) {
            setErrorMessage({ new_password: 'Password must contain at least 8 characters' })
        } else if (!passwordRegex.test(payload?.new_password)) {
            setErrorMessage({ new_password: 'Password must include uppercase, lowercase, numeric, and special characters' })
        } else if (payload?.new_password !== payload?.confirm_password) {
            setErrorMessage({ confirm_password: "New password and confirm password must be same" })
        } else {
            setErrorMessage("")
        }
    }

    const handleChangePassword = async () => {
        const res = await dispatch(actionChangePassword(payload))
        if (res?.success) {
            showSuccessMessage(res?.message)
            handleCloseModal()
        } else {
            showErrorMessage(res?.message)
        }
    }

    const handleInputChange = (event) => {
        const { value, name } = event?.target
        setPayload((prevState) => {
            return {
                ...prevState,
                [name]: value
            }
        })
        checkValidation()
    }

    return (
        <div className='change-password p-3 p-lg-4'>
            <Typography variant={"text-custom"} fontSize="text-18" fontWeight={'font-semibold'} color="color-dark01" margin="mb-3 mt-2" label={"Change Password"} />
            <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <InputGroup className='form-group-labeled'>
                        <Form.Control type={isPasswordVIsible ? "text" : "password"}
                            name="current_password"
                            className="neu-form form-md"
                            onChange={handleInputChange}
                            placeholder='Enter current password' />
                        <div id="inputGroupAppend" className='passwordAction d-flex align-items-center justify-content-center input-group-text password-icon'>
                            {!isPasswordVIsible ? <EyeCloseIcon onClick={() => setPasswordVisible(!isPasswordVIsible)} /> : <EyeOpenIcon onClick={() => setPasswordVisible(!isPasswordVIsible)} />}
                        </div>
                    </InputGroup>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <InputGroup className='form-group-labeled'>
                        <Form.Control type="text"
                            name="new_password"
                            className="neu-form form-md"
                            onChange={handleInputChange}
                            placeholder='Enter new password' />
                    </InputGroup>
                    <div className='error'>{!passwordRegex.test(payload?.new_password) ? errorMessage?.new_password : ""}</div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <InputGroup className='form-group-labeled'>
                        <Form.Control type={isConfirmPasswordVIsible ? "text" : "password"}
                            name="confirm_password"
                            className="neu-form form-md"
                            onChange={handleInputChange}
                            placeholder='Confirm new password' />
                        <div id="inputGroupAppend" className='passwordAction d-flex align-items-center justify-content-center input-group-text password-icon'>
                            {!isConfirmPasswordVIsible ? <EyeCloseIcon onClick={() => setConfirmPasswordVisible(!isConfirmPasswordVIsible)} /> : <EyeOpenIcon onClick={() => setConfirmPasswordVisible(!isConfirmPasswordVIsible)} />}
                        </div>
                    </InputGroup>
                    <div className='error'>{payload?.new_password !== payload?.confirm_password ? errorMessage?.confirm_password : ""}</div>
                </Form.Group>
            </Form>
            <Row className='mb-2'>
                <Col xs={6} className={'mt-2'}>
                    <Button label="Cancel" onClick={handleCloseModal} className={'w-100'} variant="secondary-shadow" />
                </Col>
                <Col xs={6} className={'mt-2'}>
                    <Button label="Update" onClick={handleChangePassword} className={'w-100'} variant="primary-shadow" />
                </Col>
            </Row>
        </div>
    )
}

export default ChangePasswordModal