import { GET_PROFILE_BEGINS, GET_PROFILE_FAILURE, GET_PROFILE_SUCCESS, GET_SUB_USERS_BEGINS, GET_SUB_USERS_FAILURE, GET_SUB_USERS_SUCCESS, UPDATE_SUB_USER_STATUS_SUCCESS } from "../constants";

const INITIAL_STATE = {
    subUsers: [],
    isLoadSubUsers: false,
    isLoadProfile: false,
    profile: {}
}

const settingsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_SUB_USERS_BEGINS:
            return { ...state, isLoadSubUsers: true };
        case GET_SUB_USERS_SUCCESS:
            return { ...state, isLoadSubUsers: false, subUsers: action.data };
        case GET_SUB_USERS_FAILURE:
            return { ...state, isLoadSubUsers: false };

        case GET_PROFILE_BEGINS:
            return { ...state, isLoadProfile: true };
        case GET_PROFILE_SUCCESS:
            return { ...state, isLoadProfile: false, profile: action.data };
        case GET_PROFILE_FAILURE:
            return { ...state, isLoadProfile: false };

        case UPDATE_SUB_USER_STATUS_SUCCESS:
            return { ...state, subUsers: [...state.subUsers].map((sub) => {
                if(sub.id === action.payload.sub_user_data[0].sub_user_id) {
                    return {
                        ...sub,
                        sub_user_permissions: action.payload.sub_user_data[0].permissions
                    }
                } return sub
            })}

        default:
            return state
    }
}

export default settingsReducer