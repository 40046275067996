import React, { useEffect } from 'react'
import { Row, Col, Placeholder } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { actionGetPromotionsAndOffers, actionGetQuickInsights } from '../../../../actions/dashboard';
import { Button, Carousal, Typography } from '../../../../components'
import { BackButtonIcon, OffersCategoryIcon, OffersColorIcon, OffersProductIcon, OffersSalesVolumeIcon } from '../../../../components/SVGIcons'
import { SnackMessages } from '../../../../components/Toast';
import "./index.scss";

const NewsAndOffers = () => {
    const { isLoadPromotionsAndOffers, offersandPromotions, quickInsights } = useSelector(state => state?.dashboard);
    const { showErrorMessage } = SnackMessages();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getOffers = React.useCallback(async () => {
        const res = await dispatch(actionGetPromotionsAndOffers());
        if (!res?.success) showErrorMessage(res?.message);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    const getQuickInsights = React.useCallback(async () => {
        const res = await dispatch(actionGetQuickInsights())
        if(!res?.success) {
            showErrorMessage(res?.message)
        }
    }, [dispatch, showErrorMessage])

    useEffect(() => {
        getOffers()
        getQuickInsights()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getOffers])

    const handleSelectCarousal = (item) => {
        const outerObject = offersandPromotions.find(obj => obj.news_offer_images.some(img => img.id === item?.id));
        navigate(`/promotion-details/${outerObject?.id}`)
    }

    const getTotalSalesVolume = (amt) => {
        const amtInMillion = amt/1000000
        return `$${amtInMillion ? parseFloat(amtInMillion).toFixed(2) : 0}M`
    }

    const getNewsAndOfferImages = () => {
        return (offersandPromotions?.length && offersandPromotions?.reduce((accumulator, object) => {
            return [...accumulator, ...object?.news_offer_images || []];
        }, [])?.map((newsAndOffers) => ({ ...newsAndOffers, src: newsAndOffers?.image }))) || [];
    }

    return (
        <div className="news-and-offers flex-fill-column">
            <Row className="mb-3 mb-lg-4">
                <Col xs={12} className="d-flex align-items-center gap-3 justify-content-between">
                    <div className="d-flex flex-row gap-3 align-items-center">
                        <Button variant="button-back" onClick={() => navigate(-1)} icon={<BackButtonIcon />} />
                        <Typography variant={'heading4'} margin="mb-0" label="News & Offers" />
                    </div>
                    {/* <div className="d-flex flex-row gap-3 align-items-center">
                        <Button icon={<OffersSliderIcon />} variant="secondary-shadow" className={'px-2'} />
                    </div> */}
                </Col>
            </Row>
            <div className='flex-fill-row overflow-hidden overflow-y-auto'>
                <Row className="mb-3 mx-0">
                    <Col xs={12} className='container-negative p-2'>
                        <Row className='g-2'>
                            <Col xs={12} sm={6} lg={3}>
                                <div className='news-quick-cards news-quick-item01'>
                                    <OffersColorIcon />
                                    <div className='d-flex flex-column mt-3 mt-lg-4 pt-lg-2'>
                                        <Typography variant={"text-custom"} fontSize={'text-14 opacity-90'} fontWeight={'font-semibold'} color="color-white" margin="mb-0" label={'Most Purchased Color'} />
                                        <Typography variant={"text-custom"} fontSize={'text-20'} fontWeight={'font-bold'} color="color-white" margin="mb-0" label={quickInsights?.most_purchased_color} />
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={6} lg={3}>
                                <div className='news-quick-cards news-quick-item02'>
                                    <OffersProductIcon />
                                    <div className='d-flex flex-column mt-3 mt-lg-4 pt-lg-2'>
                                        <Typography variant={"text-custom"} fontSize={'text-14 opacity-90'} fontWeight={'font-semibold'} color="color-white" margin="mb-0" label={'Most Purchased Product'} />
                                        <Typography variant={"text-custom"} fontSize={'text-20'} fontWeight={'font-bold'} color="color-white" margin="mb-0" label={quickInsights?.most_purchased_item?.item_name} />
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={6} lg={3}>
                                <div className='news-quick-cards news-quick-item03'>
                                    <OffersCategoryIcon />
                                    <div className='d-flex flex-column mt-3 mt-lg-4 pt-lg-2'>
                                        <Typography variant={"text-custom"} fontSize={'text-14 opacity-90'} fontWeight={'font-semibold'} color="color-white" margin="mb-0" label={'Most Loving Category'} />
                                        <Typography variant={"text-custom"} fontSize={'text-20'} fontWeight={'font-bold'} color="color-white" margin="mb-0" label={quickInsights?.most_purchased_material} />
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={6} lg={3}>
                                <div className='news-quick-cards news-quick-item04'>
                                    <OffersSalesVolumeIcon />
                                    <div className='d-flex flex-column mt-3 mt-lg-4 pt-lg-2'>
                                        <Typography variant={"text-custom"} fontSize={'text-14 opacity-90'} fontWeight={'font-semibold'} color="color-white" margin="mb-0" label={'Total Sales volume'} />
                                        <Typography variant={"text-custom"} fontSize={'text-20'} fontWeight={'font-bold'} color="color-white" margin="mb-0" label={getTotalSalesVolume(quickInsights?.business_value)} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                    {isLoadPromotionsAndOffers?  <CustomLoader   size="medium"/>: 
                       <Carousal handleClick={handleSelectCarousal} variant={"news-and-offers-medium"} handleActiveIndex={() => false} options={getNewsAndOfferImages().filter(items => items?.dimension === "10 x 6") || []} />}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                    {isLoadPromotionsAndOffers?  <CustomLoader   size="medium"/>: 
                       <Carousal handleClick={handleSelectCarousal} variant={"news-and-offers-medium"} handleActiveIndex={() => false} options={getNewsAndOfferImages().filter(items => items?.dimension === "7 x 3") || []} />}
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col xs={12}>
                      {isLoadPromotionsAndOffers?  <CustomLoader size="large" />:    
                        <Carousal handleClick={handleSelectCarousal} variant={"news-and-offers-large"} handleActiveIndex={() => false} options={getNewsAndOfferImages().filter(items => items?.dimension === "9 x 10") || []} />}
                    </Col>
                </Row>
            </div>
        </div>
    )
}
export default React.memo(NewsAndOffers);


const CustomLoader = ({size}) => {
    return (
        <div className="custom-loader">
            <Placeholder animation="glow" className={size}>
            <Placeholder xs={12} />
          </Placeholder>
        </div>
    )
}