import React, { useState } from 'react'
import { FormControl } from 'react-bootstrap'
import { SearchIcon } from '../SVGIcons'
import "./index.scss"

let timeout = 0;
export const Search = ({ search, handleSearchBar }) => {
    const [searchText, setsearchText] = useState(search || '')

    const handleSearch = (e) => {
        const { value } = e.target;
        setsearchText(value)
        if (timeout) {
            clearTimeout(timeout);
            timeout = 0;
        }
        timeout = setTimeout(() => {
            handleSearchBar(value)
        }, 1000)
    }

    return (
        <div className='search'>
            <div className='search-container'>
                <SearchIcon />
                <FormControl
                    placeholder="Search"
                    autoFocus
                    aria-label="Search"
                    value={searchText}
                    onChange={handleSearch}
                    aria-describedby="Search"
                />
            </div>
        </div>)
}

