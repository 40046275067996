import { CHANGE_PASSWORD_BEGINS, CHANGE_PASSWORD_FAILURE, CHANGE_PASSWORD_SUCCESS, CREATE_SUB_USER_BEGINS, CREATE_SUB_USER_FAILURE, CREATE_SUB_USER_SUCCESS, GET_PROFILE_BEGINS, GET_PROFILE_FAILURE, GET_PROFILE_SUCCESS, GET_SUB_USERS_BEGINS, GET_SUB_USERS_FAILURE, GET_SUB_USERS_SUCCESS, UPDATE_NOTIFICATION_SETTINGS_BEGINS, UPDATE_NOTIFICATION_SETTINGS_FAILURE, UPDATE_NOTIFICATION_SETTINGS_SUCCESS, UPDATE_SUB_USER_STATUS_BEGINS, UPDATE_SUB_USER_STATUS_FAILURE, UPDATE_SUB_USER_STATUS_SUCCESS } from "../../constants";
import api from "../../utils/axios";

const getSubUsersBegins = () => ({ type: GET_SUB_USERS_BEGINS })
const getSubUsersSuccess = (data) => ({ type: GET_SUB_USERS_SUCCESS, data })
const getSubUsersFailure = () => ({ type: GET_SUB_USERS_FAILURE })

const getProfileBegins = () => ({ type: GET_PROFILE_BEGINS })
const getProfileSuccess = (data) => ({ type: GET_PROFILE_SUCCESS, data })
const getProfileFailure = () => ({ type: GET_PROFILE_FAILURE })

const updateSubUserStatusBegins = () => ({ type: UPDATE_SUB_USER_STATUS_BEGINS })
const updateSubUserStatusSuccess = (payload) => ({ type: UPDATE_SUB_USER_STATUS_SUCCESS, payload })
const updateSubUserStatusFailure = () => ({ type: UPDATE_SUB_USER_STATUS_FAILURE })

const createSubUserBegins = () => ({ type: CREATE_SUB_USER_BEGINS })
const createSubUserSuccess = (data) => ({ type: CREATE_SUB_USER_SUCCESS, data })
const createSubUserFailure = () => ({ type: CREATE_SUB_USER_FAILURE })

const updateNotificationSettingsBegins = () => ({ type: UPDATE_NOTIFICATION_SETTINGS_BEGINS })
const updateNotificationSettingsSuccess = (data) => ({ type: UPDATE_NOTIFICATION_SETTINGS_SUCCESS, data })
const updateNotificationSettingsFailure = () => ({ type: UPDATE_NOTIFICATION_SETTINGS_FAILURE })

const changePasswordBegins = () => ({ type: CHANGE_PASSWORD_BEGINS })
const changePasswordSuccess = (data) => ({ type: CHANGE_PASSWORD_SUCCESS, data })
const changePasswordFailure = () => ({ type: CHANGE_PASSWORD_FAILURE })

//GET SUB USERS
export const actionGetSubUsers = () => async (dispatch) => {
    dispatch(getSubUsersBegins());
    try {
        const res = await api.get('/customer/sub-users');
        if (res.status === 200) {
            const { message, data } = res?.data || { message: 'Sub Users fetched successfully' }
            dispatch(getSubUsersSuccess(data));
            return { success: true, message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(getSubUsersFailure());
        return { success: false, message: message }
    }
}

//GET PROFILE
export const actionGetProfile = () => async (dispatch) => {
    dispatch(getProfileBegins());
    try {
        const res = await api.get('/customer/profile');
        if (res.status === 200) {
            const { message, data } = res?.data || { message: 'Profile fetched successfully' }
            dispatch(getProfileSuccess(data));
            return { success: true, message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(getProfileFailure());
        return { success: false, message: message }
    }
}

// UPDATE SUB USER STATUS
export const actionUpdateSubUserStatus = (payload) => async (dispatch) => {
    dispatch(updateSubUserStatusBegins());
    try {
        const res = await api.put('/customer/sub-user/status', payload);
        if (res.status === 200) {
            const { message } = res?.data;
            dispatch(updateSubUserStatusSuccess(payload));
            return { success: true, message }
        }
    }
    catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(updateSubUserStatusFailure());
        return { success: false, message }
    }
}

// CREATE SUB USER
export const actionCreateSubUser = (payload) => async (dispatch) => {
    dispatch(createSubUserBegins());
    try {
        const res = await api.post('/customer/sub-user', payload);
        if (res.status === 200) {
            const { message } = res?.data;
            dispatch(createSubUserSuccess());
            return { success: true, message }
        }
    }
    catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(createSubUserFailure());
        return { success: false, message }
    }
}

// UPDATE NOTIFICATION SETTINGS
export const actionUpdateNotificationSettings = (payload) => async (dispatch) => {
    dispatch(updateNotificationSettingsBegins());
    try {
        const res = await api.post('/customer/notification-settings', payload);
        if (res.status === 200) {
            const { message } = res?.data;
            dispatch(updateNotificationSettingsSuccess());
            return { success: true, message }
        }
    }
    catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(updateNotificationSettingsFailure());
        return { success: false, message }
    }
}

// CHANGE PASSWORD
export const actionChangePassword = (payload) => async (dispatch) => {
    dispatch(changePasswordBegins());
    try {
        const res = await api.put('/customer/change-password', payload);
        if (res.status === 200) {
            const { message } = res?.data;
            dispatch(changePasswordSuccess());
            return { success: true, message }
        }
    }
    catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(changePasswordFailure());
        return { success: false, message }
    }
}
