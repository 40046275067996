import { combineReducers } from "redux";
import authReducer from "./authReducers";
import productsReducer from "./productsReducer";
import wishlistReducer from "./wishlistReducer";
import cartReducer from "./cartReducer"
import dashBoardReducer from "./dashboardReducer";
import ordersReducer from "./ordersReducers";
import settingsReducer from "./settingsReducers";
import notificationReducer from "./notificationReducer";


const appReducers = combineReducers({
    authReducer,
    products: productsReducer,
    wishlist: wishlistReducer,
    cart:cartReducer,
    dashboard:dashBoardReducer,
    orders: ordersReducer,
    settings: settingsReducer,
    notification: notificationReducer,
})
export default appReducers;