import {
    CLEAR_NOTIFICATION_LIST_SUCCESS,
    GET_NOTIFICATIONS_BEGINS,
    GET_NOTIFICATIONS_FAILURE,
    GET_NOTIFICATIONS_SUCCESS,
    UPDATE_UNREAD_NOTIFICATION_COUNT_SUCCESS
} from '../constants'

const INITIAL_STATE = {
    isLoading: false,
    notification: {
        data: [],
        total: 0,
        total_unread: 0
    },
}

const concatMultiplearrays = (array1, array2) => {
    const uniq = {};
    const sum = [...array1, ...array2]
    const unniqueElements = sum?.filter(obj => !uniq[obj?.id] && (uniq[obj?.id] = true));
    return [...unniqueElements] || []
}

const notificationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_NOTIFICATIONS_BEGINS:
            return { ...state, isLoading: true };
        case GET_NOTIFICATIONS_SUCCESS:
            return {
                ...state, isLoading: false,
                notification: {
                    data: concatMultiplearrays(state?.notification?.data || [], action.data.data),
                    total: action.data.total,
                    total_unread: action?.data?.total_unread
                }
            }
        case GET_NOTIFICATIONS_FAILURE:
            return { ...state, isLoading: false };
        
        case UPDATE_UNREAD_NOTIFICATION_COUNT_SUCCESS:
            return { ...state, notification: { total_unread: action.data.total_unread, } }

        case CLEAR_NOTIFICATION_LIST_SUCCESS:
            return { ...state, notification: {
                data: [],
                total: 0,
                total_unread: 0
            }}

        default:
            return state
    }
}

export default notificationReducer