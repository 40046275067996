import {
	CHECK_OTP_BEGINS,
	CHECK_OTP_FAILURE,
	CHECK_OTP_SUCCESS,
	GET_USER_DETAILS_BEGINS, GET_USER_DETAILS_FAILURE,
	GET_USER_DETAILS_SUCCESS, LOGIN_BEGINS, LOGIN_FAILURE,
	LOGIN_SUCCESS, LOGOUT_BEGINS, LOGOUT_FAILURE, LOGOUT_SUCCESS,
	RESET_PASSWORD_BEGINS, RESET_PASSWORD_FAILURE,
	RESET_PASSWORD_SUCCESS, SEND_FORGOT_PASSWORD_BEGINS,
	SEND_FORGOT_PASSWORD_FAILURE, SEND_FORGOT_PASSWORD_SUCCESS
} from "../constants";

const INITIAL_STATE = {
	isLoading: false,
	user: {
		email: '',
		access_token: JSON.parse(localStorage.getItem('logged-in'))?.token || '',
		first_login: JSON.parse(localStorage.getItem('logged-in'))?.first_login || 0,
		isAuthenticated: JSON.parse(localStorage.getItem('logged-in'))?.token ? true : false,
		key: '',
		address:[],

	},
	isLoadUserData: false,
};

const authReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case LOGIN_BEGINS:
			return { ...state, isLoading: true }
		case LOGIN_SUCCESS:
			return { state, isLoading: false, user: { ...action.data, isAuthenticated: true } };
		case LOGIN_FAILURE:

			return { ...state, isLoading: false, }
		case LOGOUT_BEGINS:
			return { ...state, isLoading: true }
		case LOGOUT_SUCCESS:
			return { ...state, isLoading: false, user: {} }
		case LOGOUT_FAILURE:

			return { ...state, isLoading: false }
		case SEND_FORGOT_PASSWORD_BEGINS:
			return { ...state, isLoading: true }
		case SEND_FORGOT_PASSWORD_SUCCESS:
			return { ...state, isLoading: false, user: { ...action.data } };
		case SEND_FORGOT_PASSWORD_FAILURE:
			return { ...state, isLoading: false }

		case RESET_PASSWORD_BEGINS:
			return { ...state, isLoading: true }
		case RESET_PASSWORD_SUCCESS:
			return { ...state, isLoading: false, user: { ...action.data, isAuthenticated: true } }
		case RESET_PASSWORD_FAILURE:
			return { ...state, isLoading: false }

		case GET_USER_DETAILS_BEGINS:
			return { ...state, isLoadUserData: true }
		case GET_USER_DETAILS_SUCCESS:
			return { ...state, isLoadUserData: false, user: { ...state.user, ...action.data } }
		case GET_USER_DETAILS_FAILURE:
			return { ...state, isLoadUserData: false }

		case CHECK_OTP_BEGINS:
			return { ...state, isLoading: true }
		case CHECK_OTP_SUCCESS:
			return { ...state, isLoading: false }
		case CHECK_OTP_FAILURE:
			return { ...state, isLoading: false }
		
		default:
			return state;
	}
};

export default authReducer;