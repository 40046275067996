// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA_s-CVolO78hgfxtKL_zf13rV857hWDuk",
  authDomain: "tab-surfaces.firebaseapp.com",
  projectId: "tab-surfaces",
  storageBucket: "tab-surfaces.appspot.com",
  messagingSenderId: "271828039257",
  appId: "1:271828039257:web:7566a68cfeb4a50358a348",
  measurementId: "G-NC6Y89GZ1H"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

export const messaging = getMessaging(app);

export const fetchToken = (setTokenFound) => {
    return getToken(messaging, { vapidKey: 'BIPMjFlX72u0yiw0cLuPXOacuKrmdsfeG-dn9DU1EJGP1cDo46mGCvL-YQkB7BaniCImfmkPSPlUAw213RoYBg4' }).then((currentToken) => {
        if (currentToken) {
            console.log('current token for client: ', currentToken);
            setTokenFound(currentToken);
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            console.log('No registration token available. Request permission to generate one.');
            setTokenFound(false);
            // shows on the UI that permission is required 
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
           return resolve(payload);
        });
    });