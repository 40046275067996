import React from 'react'
import { Modal } from 'react-bootstrap';
import { CloseModalIcon } from '../SVGIcons';
import "./index.scss";


function CustomModal(props) {
    const { children, handleclosemodal, className, size } = props;

    return (

        <Modal {...props} size={size || 'md'}
            className={`custom-modal ${className}`}
            aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Title id="contained-modal-title-vcenter">
                <span onClick={handleclosemodal}>
                    <CloseModalIcon />
                </span>
            </Modal.Title>
            <Modal.Body>
                {children}
            </Modal.Body>

        </Modal>

    );
}


const CustomDialogue = (props) => {
    const { children, modalShow, handleCloseModal, className, size } = props
    const handleclosemodal = () => handleCloseModal()

    return (
        <>
            <CustomModal
                show={modalShow}
                handleclosemodal={handleclosemodal}
                children={children}
                className={className}
                size={size}
                onHide={() => handleCloseModal()}
            />
        </>
    )
}

export default CustomDialogue