import React from 'react'
import { Button, Typography } from '../..'
import "./index.scss"

const DialogueChildren = ({ content }) => {
  const { title, noButtonText, label,subTitle,  yesButtonText, yesButtonHandler, noButtonhandler, sublabel2, icon, buttonIcon,pos,description, buttonMargin } = content;

  return (
    <div className='dialogue-children'>
      <div className='dialogue-icon mb-3 mb-lg-4'>
        {icon || ''}
      </div>

      <div className='dialogue-title'>
        <Typography variant={'subtitle'} label={title} sublabel={label} sublabel2={sublabel2} />
        <Typography variant={'subtitle2'} label={subTitle} />
        <Typography variant={'subtitle2'} label={description} margin="mt-3" color="color-gray03" />
      </div>
      
      <div className={`dialogue-buttons ${buttonMargin}`}>
        {noButtonText && <Button label={noButtonText} onClick={noButtonhandler} variant="secondary-shadow" />}
        {yesButtonText && <Button label={yesButtonText} onClick={yesButtonHandler} icon={buttonIcon ? buttonIcon : ''} variant="primary-shadow" pos={pos} />}
      </div>
    </div>)
}
export default DialogueChildren