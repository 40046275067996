import React, { Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, Navigate } from "react-router-dom";
import { actionGetUserDetails } from '../../actions/auth';
import { AppBar, Spinner } from '../../components';
import { SnackMessages } from '../../components/Toast';
import NewsAndOffers from './DashboardScreen/News&Offers';
import Notifications from './Notifications';
import Settings from './Settings';
import "./index.scss";
// import { Orders } from './Orders';

const Sidebar = React.lazy(() => import('../../components/Sidebar'));
const DashBoardScreen = React.lazy(() => import('./DashboardScreen'));
const Products = React.lazy(() => import('./Products'));
const Wishlist = React.lazy(() => import('./Wishlist'));
const Cart = React.lazy(() => import('./Cart'));
const Profile = React.lazy(() => import('./Profile'));
const MyOrders = React.lazy(() => import('./Orders'));
const PrivacyPolicy = React.lazy(() => import('./PrivacyPolicy'));
const PromotionDetails = React.lazy(() => import('./DashboardScreen/News&Offers/PromotionsDetails'));
const SimilarProducts = React.lazy(() => import('./DashboardScreen/News&Offers/SimilarProducts'));



export const DashBoard = (props) => {
    const dispatch = useDispatch();
    const { showErrorMessage } = SnackMessages();
    const { user } = useSelector((state) => state.authReducer)
    const [startAPICall, setstartAPICall] = useState(true);
    const [loadRange, setLoadRange] = useState(false)

    const ProtectedRoute = ({ children }) => (<>{user?.isAuthenticated ? children : <Navigate to="/auth/login" />} </>)


    const getUserDetails = React.useCallback(async () => {
        const res = await dispatch(actionGetUserDetails())
        if (!res.success) {
            showErrorMessage(res?.message)
        }
        setstartAPICall(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])


    useEffect(() => {
        if (startAPICall) {
            getUserDetails()
        }
        return () => {
            return null;// here ref.current might be null
        }
    }, [startAPICall, getUserDetails])

    useEffect(() => {
        setLoadRange(true)
    }, [loadRange])
    

    return (
        <Suspense fallback={<Spinner />}>
            <div className='dashboard'>
                {loadRange&& <Sidebar />}
                <div className='main'>
                    <AppBar />
                    <Routes>
                        <Route path="/dashboard" element={<ProtectedRoute> <DashBoardScreen /></ProtectedRoute>} />
                        <Route path="/products" element={<ProtectedRoute> <Products /></ProtectedRoute>} />
                        <Route path="/wishlist" element={<ProtectedRoute> <Wishlist /></ProtectedRoute>} />
                        <Route path="/news-and-offers" element={<ProtectedRoute> <NewsAndOffers /></ProtectedRoute>} />
                        <Route path="/promotion-details/:id" element={<ProtectedRoute> <PromotionDetails /></ProtectedRoute>} />
                        <Route path="/similar-products" element={<ProtectedRoute> <SimilarProducts /></ProtectedRoute>} />
                        <Route path="/cart" element={<ProtectedRoute> <Cart /></ProtectedRoute>} />
                        <Route path="/profile" element={<ProtectedRoute> <Profile /></ProtectedRoute>} />
                        <Route path="/orders" element={<ProtectedRoute> <MyOrders /></ProtectedRoute>} />
                        <Route path="/settings" element={<ProtectedRoute> <Settings /></ProtectedRoute>} />
                        <Route path="/privacy-policy" element={<ProtectedRoute> <PrivacyPolicy /></ProtectedRoute>} />
                        <Route path="/notifications" element={<ProtectedRoute> <Notifications /></ProtectedRoute>} />
                        <Route path="*" element={<Navigate to={"/404"} />} />
                    </Routes>
                </div>
            </div>
        </Suspense>
    )
}

