import React from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { Radio,ButtonSpinner } from '..';
import { CloseCircleIcon } from '../SVGIcons';
import "./index.scss"

export const DropDownButton = ({ variant, title, icon, options, handleSortBy, onSelectItem, customTitle, isOpen,
    handleToggle, iconPos, type, size, customComponent, customClass, handleDelete, loader, disabled }) => {

    const onSelect = (eventKey, event) => {
        event.preventDefault();
        event.persist();
        event.stopPropagation();
        onSelectItem(eventKey)
    }



    const renderDropDown = () => {
        switch (variant) {
            case 'button-dropdown-with-radio':
                return (<div className='button-dropdown'>
                    <Dropdown className="d-inline-flex flex-column" show={isOpen} onToggle={handleToggle} onSelect={onSelect}>
                        <Dropdown.Toggle className={`${size} ${type && type === 'info' ? 'btn-info' : 'secondary-gray'}`} id="dropdown-autoclose-false">
                            {icon && iconPos !== 'right' ? icon : ''} {title} {icon && iconPos === 'right' ? icon : ''}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <>
                                {customTitle && customTitle}
                                {options?.length > 0 &&
                                    options.map((option, index) =>
                                        <Dropdown.Item active={option.checked} key={index} eventKey={option.key} >
                                            <Radio checked={option.checked} label={option?.name} />
                                        </Dropdown.Item>
                                    )}
                            </>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>)

            case 'dropdown':
                return (<div className='button-dropdown'>
                    <Dropdown className="d-inline-flex flex-column" show={isOpen} onToggle={handleToggle} onSelect={onSelect}>
                        <Dropdown.Toggle className={`${size} ${type && type === 'info' ? 'btn-info' : 'secondary-gray'}`} id="dropdown-autoclose-false" disabled={disabled}>
                            {icon && iconPos !== 'right' ? icon : ''} {title} {icon && iconPos === 'right' ? icon : ''}
                        </Dropdown.Toggle>
                        <Dropdown.Menu as={'div'}
                            className={customClass ? customClass : ''}>
                            <>
                                {customTitle && customTitle}
                                {options?.length > 0 &&
                                    options.map((option, index) =>
                                        <Dropdown.Item
                                            active={option?.checked} key={index} eventKey={option.key} >
                                            {option?.name}
                                            {loader?.loader && loader?.index === index ?
                                                <ButtonSpinner />
                                                : <>
                                                    {option?.customer_id !== null && <span onClick={(e) => handleDelete(e, option)}>
                                                        <CloseCircleIcon />
                                                    </span>}
                                                </>}
                                        </Dropdown.Item>
                                    )}
                            </>
                            {customComponent && customComponent}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>)

            default:
                return (
                    <DropdownButton align="end" title={title} id="dropdown-menu-align-end">
                        {options?.length > 0 &&
                            options.map((option, index) =>
                                <Dropdown.Item onClick={() => handleSortBy(option?.key, index)}
                                    active={option.checked} key={index} eventKey={option?.key}>{option?.name}</Dropdown.Item>)}
                    </DropdownButton>)
        }

    }

    return (
        <div className='dropdowns'>
            {renderDropDown()}
        </div>
    )
}
