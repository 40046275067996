import React from 'react'
import { Carousal } from '..'
import "./index.scss"

export const StoryModal = ({ options, loader ,handleCloseModal }) => {
    return (
        <div>
            <Carousal
            loader={loader}
            handleCloseModal={handleCloseModal}
                options={options}
                variant="story-carousal"
            />
        </div>
    )
}
