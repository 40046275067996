import React from 'react'
import { Typography } from '../Typography'
import { Button } from '..'
import { Link } from 'react-router-dom'
import { Avatars } from '../Avatars'

export const NotFound = () => {
  return (
    <div className='d-flex align-items-center justify-content-center vh-100'>
      <div className="text-center mx-3">
        <Avatars src="/images/logo-red.svg" />
        <Typography variant={'text-custom'} fontSize="text-36" fontWeight="font-bold" color="color-info" margin="my-2" label="404" />
        <Typography variant={'text-custom'} fontSize="text-20" fontWeight="font-bold" color="color-info" margin="my-2" label="Not Found" />
        <Typography variant={'text-custom'} fontSize="text-16"  color="color-info" margin="my-3" label="The requested URL was not found on this server" />
        <Link to={"/"}><Button variant="secondary-shadow" label={"Go back home"} /></Link>
      </div>
    </div>
  )
}
