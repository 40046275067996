
import {
    CLEAR_PRODUCT_DETAILS_SUCCESS,
    CLEAR_PRODUCT_LIST_SUCCESS, GET_PRODUCT_DETAILS_BEGINS, GET_PRODUCT_DETAILS_FAILURE, GET_PRODUCT_DETAILS_SUCCESS, GET_PRODUCT_FILITERS_BEGINS, GET_PRODUCT_FILITERS_FAILURE, GET_PRODUCT_FILITERS_SUCCESS, GET_PRODUCT_LIST_BEGINS, GET_PRODUCT_LIST_FAILURE, GET_PRODUCT_LIST_SUCCESS, PRODUCT_ADD_TO_WISH_LIST_BEGINS,
    PRODUCT_ADD_TO_WISH_LIST_FAILURE, PRODUCT_ADD_TO_WISH_LIST_SUCCESS, REMOVE_ALL_FROM_WISH_LIST_SUCCESS, SHOW_INTEREST_ON_PRODUCT_BEGINS, SHOW_INTEREST_ON_PRODUCT_FAILURE, SHOW_INTEREST_ON_PRODUCT_SUCCESS, UPDATE_PRODUCT_FILTERS_SUCCESS
} from "../constants";

const INITIAL_STATE = {
    isLoading: false,
    products: {
        data: [],
        total: 0,
        totalPages: 0,
        viewby: 0
    },
    isLoadproductDetails: false,
    productDetails: {},
    isLoadproductFilters: false,
    productFilters: [],
    isWishlistLoad: false,
    isInterestLoad: false
};

const updateWishListsWithId = (array, data) => {
    const newArray = [...array]
    const index = newArray?.findIndex(item => item.id === data.id);
    newArray[index].is_fav = data.checked;
    return newArray;
}

const removeAlltheWishedItemsFromProducts = (products) => (products?.length && products?.map(product => ({
    ...product,
    is_fav: false
}))) || []

const concatMultiplearrays = (array1, array2) => {
    const uniq = {};
    const sum = [...array1, ...array2]
    const unniqueElements = sum?.filter(obj => !uniq[obj?.id] && (uniq[obj?.id] = true));
    return [...unniqueElements] || []

  }

const productsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_PRODUCT_LIST_BEGINS:
            return { ...state, isLoading: true };
        case GET_PRODUCT_LIST_SUCCESS:
            return {
                ...state, isLoading: false,
                products: {
                    ...state.products,
                    data: concatMultiplearrays(state?.products?.data || [], action.data.data),
                    total: action.data.total,
                    totalPages: action.data.totalPages,
                    viewby: action.data.viewby
                }
            };
        case GET_PRODUCT_LIST_FAILURE:
            return { ...state, isLoading: false };

        case CLEAR_PRODUCT_LIST_SUCCESS:
            return {
                ...state, products: {
                    data: [],
                    total: 0,
                    totalPages: 0,
                    viewby: 0
                }
            };

        case CLEAR_PRODUCT_DETAILS_SUCCESS:
            return { ...state, productDetails: {} }

        case GET_PRODUCT_FILITERS_BEGINS:
            return { ...state, isLoadproductFilters: true };
        case GET_PRODUCT_FILITERS_SUCCESS:
            return {
                ...state, isLoadproductFilters: false,
                productFilters: action.data
            };
        case GET_PRODUCT_FILITERS_FAILURE:
            return { ...state, isLoadproductFilters: false };

        case UPDATE_PRODUCT_FILTERS_SUCCESS:
            return { ...state, productFilters: action.data };

        case GET_PRODUCT_DETAILS_BEGINS:
            return { ...state, isLoadproductDetails: true };
        case GET_PRODUCT_DETAILS_SUCCESS:
            return {
                ...state, isLoadproductDetails: false,
                productDetails: action.data
            };
        case GET_PRODUCT_DETAILS_FAILURE:
            return { ...state, isLoadproductDetails: false };

        case PRODUCT_ADD_TO_WISH_LIST_BEGINS:
            return { ...state, isWishlistLoad: true };
        case PRODUCT_ADD_TO_WISH_LIST_SUCCESS:
            return {
                ...state, isWishlistLoad: false, products: {
                    ...state.products,
                    data: action.data.type !== 'remove-only' && updateWishListsWithId(state?.products?.data || [], action.data)
                }
            };
        case PRODUCT_ADD_TO_WISH_LIST_FAILURE:
            return { ...state, isWishlistLoad: false };

        case SHOW_INTEREST_ON_PRODUCT_BEGINS:
            return { ...state, isInterestLoad: true };
        case SHOW_INTEREST_ON_PRODUCT_SUCCESS:
            return { ...state, isInterestLoad: false };
        case SHOW_INTEREST_ON_PRODUCT_FAILURE:
            return { ...state, isInterestLoad: false };

        case REMOVE_ALL_FROM_WISH_LIST_SUCCESS:
            return {
                ...state, products: {
                    ...state.products,
                    data: state?.products?.data?.length && removeAlltheWishedItemsFromProducts(state?.products?.data)
                }
            };


        default:
            return state;
    }
};

export default productsReducer;