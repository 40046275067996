import React from 'react';

import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { NotFound } from '../components';
import AllComponents from '../views/AllComponents';
import { Authentication } from '../views/Authentication';
import { DashBoard } from '../views/Dashboard';

const DefaultRoutes = () => {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Navigate to="/auth" />} />
                    <Route path="/auth/*" element={<Authentication />} />
                    <Route path="/*" element={<DashBoard />} />
                    <Route path="/components/*" element={<AllComponents />} />
                    <Route path="/404" element={<NotFound />} />
                </Routes>
            </BrowserRouter>

        </>
    )
}

export default DefaultRoutes;

