import React from 'react';
import { Card } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import "./index.scss"

export const CustomAccordion = ({ variant, options, activeKey }) => {

    const renderAccordion = () => {
        switch (variant) {
            case 'order-tab':
                return (
                    <Accordion className='order-tab' defaultActiveKey={activeKey}>
                        {options?.length > 0 && options.map((option, idx) =>
                            <Card key={option?.id} >
                                <Card.Header>
                                    {option?.titleComponent}
                                </Card.Header>
                                <Accordion.Collapse eventKey={idx}>
                                    <Card.Body className='bg-white rounded-3 p-3 pt-0'>{option?.bodyComponent}</Card.Body>
                                </Accordion.Collapse>
                            </Card>)}
                    </Accordion>
                )
                case 'settings-usermanagement':
                    return (
                        <Accordion className='settings-usermanagement' defaultActiveKey={activeKey}>
                            {options?.length > 0 && options.map((option, idx) =>
                                <Card key={option.id} >
                                    <Card.Header>
                                        {option?.titleComponent}
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={idx}>
                                        <Card.Body className='bg-white rounded-3 p-3'>{option?.bodyComponent}</Card.Body>
                                    </Accordion.Collapse>
                                </Card>)}
                        </Accordion>
                    )
            default:
                return (
                    <Accordion>
                        {options?.length > 0 && options.map((option, idx) =>
                            <Accordion.Item eventKey={idx} key={option?.id}>
                                <Accordion.Header>{option?.titleComponent}</Accordion.Header>
                                <Accordion.Body>
                                    {option?.bodyComponent}
                                </Accordion.Body>
                            </Accordion.Item>)}
                    </Accordion>)
        }
    }


    return (
        <div className='accordion'>
            {renderAccordion()}
        </div>)
}
