import React, { useEffect, useState } from 'react'
import RangeSlider from 'react-bootstrap-range-slider';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { SilverIcon, GoldIcon, PlatinumIcon, DiamondIcon } from '../SVGIcons';
import "./index.scss";

export const CustomRange = ({ value, variant, label, range, label1, label2, point }) => {

    const [values, setValues] = useState([]);
    const [membershipType, setMembershipType] = useState()

    const getProgressBarColor = (point) => {
        if (point >= 3) {
            setMembershipType("diamond")
        } else if (point > 1) {
            setMembershipType("platinum")
        } else if (point > .50) {
            setMembershipType("gold")
        } else if (point > .25) {
            setMembershipType("silver")
        }
    }

    const CustomTooltip = ({ value }) => {
        return <div className="tooltip">{`$${value}M`}</div>;
    };

    useEffect(() => {
        setValues(value)
        getProgressBarColor(point)
    }, [point, value])

    const renderRangesComponents = () => {
        switch (variant) {
            case 'two-point-range':
                return (
                    <div className='two-point-range'>
                        {range?.length && values?.length && <>
                            <div className={`range-slider ${membershipType}`} style={{ '--percentage': value + '%' }}>
                                {point > 0 && point > 1 ? <PlatinumIcon width="36" height="36" /> : values[0] > .50 ? <GoldIcon width="36" height="36" /> : values[0] > .25 ? <SilverIcon width="36" height="36" /> : ""}
                                <RangeSlider
                                    min={range[0]}
                                    max={range[1]}
                                    value={values[0]}
                                    onChange={false}
                                    tooltipPlacement="top"
                                    tooltip="on"
                                    tooltipLabel={(values) => <CustomTooltip value={point} />}
                                />
                                {point > 0 && point > 1 ? <DiamondIcon width="36" height="36" /> : point > .50 ? <PlatinumIcon width="36" height="36" /> : point > .25 ? <GoldIcon width="36" height="36" /> : ""}
                            </div>
                            <div className='range-description d-flex justify-content-between'>
                                <span>
                                    {label1}
                                </span>
                                <span>
                                    {label2}
                                </span>
                            </div>
                        </>}
                    </div>
                )
            case 'three-point-range':
                return (
                    <div className='three-point-range'>
                        <div className='range-slider' style={{ '--percentage': value + '%' }}>
                            <SilverIcon width="46" height="46" />
                            <GoldIcon width="46" height="46" className="gold-icon" />
                            <PlatinumIcon width="46" height="46" />
                            <RangeSlider
                                min={range[0]}
                                max={range[1]}
                                value={values[0]}
                                onChange={false}
                                tooltipPlacement="top"
                                tooltip="on"
                                tooltipLabel={(values) => <CustomTooltip value={point} />} />
                            <DiamondIcon width="46" height="46" />
                        </div>
                        <div className='range-description'>
                            <div className='description-element silver'>
                                <span className='icon-title'>
                                    Silver
                                </span>
                                <span className='icon-value text-nowrap'>
                                    {`$ 250 k+`}
                                </span>
                            </div>
                            <div className='description-element gold'>
                                <span className='icon-title'>
                                    Gold
                                </span>
                                <span className='icon-value text-nowrap'>
                                    {`$ 500 k+`}
                                </span>
                            </div>
                            <div className='description-element platinum'>
                                <span className='icon-title'>
                                    Platinum
                                </span>
                                <span className='icon-value text-nowrap'>
                                    {`$ 1 Million+`}
                                </span>
                            </div>
                            <div className='description-element diamond'>
                                <span className='icon-title'>
                                    Diamond
                                </span>
                                <span className='icon-value text-nowrap'>
                                    {`$ 3 Million+`}
                                </span>
                            </div>
                        </div>

                    </div>
                )
            default:
                return (
                    <RangeSlider
                        value={values}
                        onChange={false}
                        tooltip="on"
                        tooltipLabel={(value) => <CustomTooltip value={value} />} />
                )
        }

    }


    return (
        <div className='range'>
            {renderRangesComponents()}

        </div>
    )
}
