
import './App.scss';
import DefaultRoutes from './Routes';
import { ToastContainer } from 'react-toastify';
import { onMessageListener } from './firebase';

function App() {

  onMessageListener().then(payload => {
    new Notification("Notification Title", {
      body: payload?.notification?.body,
      icon: payload?.notification?.title, // Optional icon
    });
  }).catch(err => console.log('failed: ', err));

  return (
    <div className="App">
      <ToastContainer autoClose={1000} />
      <DefaultRoutes />
    </div>
  );
}

export default App;
