import ChatPopUp from '../Chat';
import { db } from '../../firebase';
import { SnackMessages } from '../Toast';
import { Placeholder } from 'react-bootstrap';
import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actionGetCartLists } from '../../actions/cart';
import { Button, Avatar, Typography, Drawer } from '..'
import { ChatIcon, NotificationIcon, CartIcon } from '../SVGIcons'
import { collection, getDocs, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import "./index.scss";
import { actionGetNotifications } from '../../actions/notifications';

export const AppBar = () => {
  const dispatch = useDispatch()
  const { cart } = useSelector((state) => state?.cart);
  const { notification } = useSelector(state => state?.notification);
  const { showErrorMessage } = SnackMessages()
  const { isLoadUserData, user } = useSelector((state) => state.authReducer)
  const [pageProps,] = useState({ page: 0, limit: 10 });
  const [chat, setChat] = useState([])
  const [customer, setCustomer] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [bottomDrawer, setbottomDrawer] = useState({
    isOpen: false,
    position: 'bottom',
    data: {},
    type: '',
    title: '',
  })

  const getMembershipType = (point) => {
    const pointValue = Number(point)
    if (pointValue >= 3000000) {
      return "diamond";
    } else if (pointValue >= 1000000) {
      return "platinum";
    } else if (pointValue >= 500000) {
      return "gold";
    } else if (pointValue >= 250000) {
      return "silver";
    } else {
      return "";
    }
  }

  const handleCloseDrawer = () => setbottomDrawer({ ...bottomDrawer, isOpen: false })

  const handleChatPopup = () => setbottomDrawer({ ...bottomDrawer, isOpen: true, position: 'bottom' })

  const fetchNotifications = useCallback(async () => {
    const res = await dispatch(actionGetNotifications({ page: pageProps.page, limit: pageProps.limit }))
    if (!res?.success) {
      showErrorMessage(res?.message)
    }
  }, [dispatch, pageProps, showErrorMessage])

  const getCartlist = useCallback(async () => {
    const res = await dispatch(actionGetCartLists({ page: pageProps.page, limit: pageProps.limit }));
    if (!res?.success) { showErrorMessage(res.message) }
    // eslint-disable-next-line 
  }, [dispatch, pageProps]);

  const getUserChat = async (e) => {
    const userResponse = query(
      collection(db, "users"),
      where("email", "==", e.email)
    );
    const userData = await getDocs(userResponse);
    let id = "";
    userData.forEach((_data) => {
      id = _data.id;
    });
    setCustomer(e);
    const response = query(
      collection(db, "users", id, "messages"),
      orderBy("createdOn", "asc")
    );
    const data = await getDocs(response);
    const dataUsers = [];
    data.forEach((_data) => {
      dataUsers.push(_data.data());
    });
    setChatHistory(dataUsers);
  };

  const fetchPost = async () => {
    const getUserEmail = user?.email
    const users = query(
      collection(db, "users"),
      where("email", "==", getUserEmail)
    );
    const allUsers = await getDocs(users);
    let userId = "";
    allUsers.forEach((doc) => {
      userId = doc.id;
    });
    const q = query(
      collection(db, "users", userId, "messages"),
      orderBy("createdOn", "asc"));
    onSnapshot(q, async (querySnapshot) => {
      const data = await getDocs(q);
      const dataUsers = [];
      data.forEach((_data) => {
        dataUsers.push(_data.data());
      });
      setChat(dataUsers)
    });
  }

  useEffect(() => {
    getCartlist()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchNotifications()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='appBar'>
      <Drawer data={bottomDrawer} variant={"chatWidget"}
        children={<ChatPopUp
          chatHistory={chatHistory}
          chat={chat}
          customer={customer}
          getUserChat={getUserChat}
          fetchPost={fetchPost}
          type={bottomDrawer?.type}
          handleCloseDrawer={handleCloseDrawer} />}
        handleCloseDrawer={handleCloseDrawer} />
      <div className='d-flex align-items-center justify-content-between flex-xl-nowrap flex-wrap text-left gap-3'>
        <div className='text-nowrap w-auto d-none d-xl-block'>
          {isLoadUserData ? <LoaderSkeleton /> :
            <Typography variant={'welcomeTitle'} margin="m-0" label="Welcome Back," sublabel={user?.username || ''} />}
        </div>
        <div className='d-flex align-items-center justify-content-end flex-wrap gap-3 gap-md-4 w-100'>
          <div className='d-flex align-items-center justify-content-end flex-wrap gap-3'>
            {isLoadUserData ? <>
              <LoaderThumb />
              <LoaderThumb />
              <LoaderThumb />
            </>
              : <>
                <Button icon={<ChatIcon />} onClick={handleChatPopup} variant="primary-rounded" />
                <Link to="/cart"><Button icon={<CartIcon />} variant="white-rounded" count={cart?.total || ''} /></Link>
                <Link to="/notifications"><Button icon={<NotificationIcon />} variant="white-rounded" count={notification?.total_unread || ""} /></Link>
              </>}
          </div>
          {isLoadUserData ? <LoaderThumb />
            : <Link to="/profile"><Avatar variant={'header-pro-thumb'} membership={getMembershipType(user?.loyality_amount)} bsPrefix src={user?.profileImage || ''} /></Link>}
        </div>
      </div>
    </div>
  )
}

const LoaderThumb = () => {
  return (
    <>
      <Placeholder style={{ width: 58, height: 58, borderRadius: 50 }} />
    </>
  )
}


const LoaderSkeleton = () => {
  return (
    <div className='d-flex flex-column gap-1'>
      <Placeholder animation="glow">
        <Placeholder xs={6} style={{ width: 144, height: 22 }} />
      </Placeholder>
      <Placeholder animation="glow">
        <Placeholder xs={6} style={{ width: 264, height: 53 }} />
      </Placeholder>
      <Placeholder animation="glow">
        <Placeholder xs={6} style={{ width: 264, height: 24 }} />
      </Placeholder>
    </div>
  )
}
