import { DashBoardMenuIcon, ProductMenuIcon, OrdersMenuIcon, WishlistMenuIcon, CartMenuIcon, ProfileMenuIcon, SettingsMenuIcon, PrivacyMenuIcon, InfoMenuIcon } from "../components/SVGIcons";



export const sidebarMenus = [

    {
        id: 1,
        name: 'Dashboard',
        icon: DashBoardMenuIcon,
        disabled: false,
        path: '/dashboard',
    },
    {
        id: 2,
        name: 'Products',
        icon: ProductMenuIcon,
        disabled: false,
        path: '/products',
    },
    {
        id: 3,
        name: 'My Orders',
        icon: OrdersMenuIcon,
        disabled: false,
        path: '/orders',
    },
    {
        id: 9,
        name: 'Info',
        icon: InfoMenuIcon,
        disabled: false,
        path: '/news-and-offers',
    },
    {
        id: 4,
        name: 'My Wishlist',
        icon: WishlistMenuIcon,
        disabled: false,
        path: '/wishlist',
    },
    {
        id: 5,
        name: 'My Cart',
        icon: CartMenuIcon,
        disabled: false,
        path: '/cart',
        class: 'd-none d-sm-flex',
    },
    {
        id: 6,
        name: 'Profile',
        icon: ProfileMenuIcon,
        disabled: false,
        path: '/profile',
    },
    {
        id: 7,
        name: 'Settings',
        icon: SettingsMenuIcon,
        disabled: false,
        path: '/settings',
        class: 'd-none d-sm-flex',
    },
    {
        id: 8,
        name: 'Privacy Policy',
        icon: PrivacyMenuIcon,
        disabled: false,
        path: '/privacy-policy',
    }

]