import { CLEAR_ORDERS_LIST_SUCCESS, GET_ORDERS_LIST_BEGINS, GET_ORDERS_LIST_FAILURE, GET_ORDERS_LIST_SUCCESS, GET_ORDERS_SUB_ORDER_DETAILS_BEGINS, GET_ORDERS_SUB_ORDER_DETAILS_FAILURE, GET_ORDERS_SUB_ORDER_DETAILS_SUCCESS, GET_ORDER_ACTIVE_DETAILS_BEGINS, GET_ORDER_ACTIVE_DETAILS_FAILURE, GET_ORDER_ACTIVE_DETAILS_SUCCESS, GET_ORDER_HISTORY_DETAILS_BEGINS, GET_ORDER_HISTORY_DETAILS_FAILURE, GET_ORDER_HISTORY_DETAILS_SUCCESS, GET_ORDER_REQUEST_DETAILS_BEGINS, GET_ORDER_REQUEST_DETAILS_FAILURE, GET_ORDER_REQUEST_DETAILS_SUCCESS } from "../constants";



const INITIAL_STATE = {
    isLoading: false,
    orders: {
        data: [],
        total: 0,
        totalPages: 0,
        viewby: 0
    },
    isLoadOrderRequestDetails: false,
    orderRequestDetails: {},
    isLoadOrderActiveDetails: false,
    orderActiveDetails: {},
    isLoadOrderHistoryDetails: false,
    orderHistoryDetails: {},
    isLoadOrderSubOrderDetails: false,
    ordersSubOrderDetails: {}
};


const concatMultiplearrays = (array1, array2) => {
    const uniq = {};
    const sum = [...array1, ...array2]
    const unniqueElements = sum?.filter(obj => !uniq[obj?.id] && (uniq[obj?.id] = true));
    return [...unniqueElements] || []

}

const ordersReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ORDERS_LIST_BEGINS:
            return { ...state, isLoading: true };
        case GET_ORDERS_LIST_SUCCESS:
            return {
                ...state, isLoading: false,
                orders: {
                    ...state.orders,
                    data: concatMultiplearrays(state?.orders?.data || [], action.data.data),
                    total: action.data.total,
                    totalPages: action.data.totalPages,
                    viewby: action.data.viewby
                }
            };
        case GET_ORDERS_LIST_FAILURE:
            return { ...state, isLoading: false };

        case GET_ORDER_REQUEST_DETAILS_BEGINS:
            return { ...state, isLoadOrderRequestDetails: true };
        case GET_ORDER_REQUEST_DETAILS_SUCCESS:
            return {
                ...state, isLoadOrderRequestDetails: false,
                orderRequestDetails: action.data
            };
        case GET_ORDER_REQUEST_DETAILS_FAILURE:
            return { ...state, isLoadOrderRequestDetails: false };

        case GET_ORDER_ACTIVE_DETAILS_BEGINS:
            return { ...state, isLoadOrderActiveDetails: true };
        case GET_ORDER_ACTIVE_DETAILS_SUCCESS:
            return {
                ...state, isLoadOrderActiveDetails: false,
                orderActiveDetails: action.data
            };
        case GET_ORDER_ACTIVE_DETAILS_FAILURE:
            return { ...state, isLoadOrderActiveDetails: false };

        case GET_ORDER_HISTORY_DETAILS_BEGINS:
            return { ...state, isLoadOrderHistoryDetails: true };
        case GET_ORDER_HISTORY_DETAILS_SUCCESS:
            return {
                ...state, isLoadOrderHistoryDetails: false,
                orderHistoryDetails: action.data
            };
        case GET_ORDER_HISTORY_DETAILS_FAILURE:
            return { ...state, isLoadOrderHistoryDetails: false };

        case GET_ORDERS_SUB_ORDER_DETAILS_BEGINS:
            return { ...state, isLoadOrderSubOrderDetails: true };
        case GET_ORDERS_SUB_ORDER_DETAILS_SUCCESS:
            return {...state, isLoadOrderSubOrderDetails: false, ordersSubOrderDetails: action.data};
        case GET_ORDERS_SUB_ORDER_DETAILS_FAILURE:
            return { ...state, isLoadOrderSubOrderDetails: false };

        case CLEAR_ORDERS_LIST_SUCCESS:
            return { ...state, orders: {
                data: [],
                total: 0,
                totalPages: 0,
                viewby: 0
            }}


        default:
            return state;
    }
};

export default ordersReducer;