import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { Typography } from '../../../../../components'
import "./index.scss"

export const PersonalInfoTab = React.memo(({ profile }) => {
    return (
        <div className='profile-info-tab'>
            <Form>
                <Row>
                    <Col xs={12} md={6} lg={4} className={'mb-3'}>
                        <Typography variant={"text-custom"} fontSize="text-14" fontWeight={'font-medium'} color="color-med-gray" margin="mb-1" label={"Full Name"} />
                        <Form.Group>
                            <Form.Control value={`${profile?.first_name} ${profile?.last_name}`} className='neu-form' disabled />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={4} className={'mb-3'}>
                        <Typography variant={"text-custom"} fontSize="text-14" fontWeight={'font-medium'} color="color-med-gray" margin="mb-1" label={"Email"} />
                        <Form.Group>
                            <Form.Control value={profile?.email} className='neu-form' disabled />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6} lg={4} className={'mb-3'}>
                        <Typography variant={"text-custom"} fontSize="text-14" fontWeight={'font-medium'} color="color-med-gray" margin="mb-1" label={"Phone"} />
                        <Form.Group>
                            <Form.Control value={profile?.contact_number} className='neu-form' disabled />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
        </div>
    )
})

// export default PersonalInfoTab