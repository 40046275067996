import moment from "moment";
import { useNavigate } from "react-router-dom";
import ChatPopUp from '../../../components/Chat';
import { Badge, Col, Placeholder, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { SnackMessages } from '../../../components/Toast';
import React, { useCallback, useEffect, useState } from 'react'
import { Avatar, Button, Card, Drawer, Scroller, Typography } from '../../../components'
import { actionClearNotificationList, actionClearNotifications, actionGetNotifications, actionNotificationMarkasRead, actionUpdateNotificationCount } from '../../../actions/notifications';
import { BackButtonIcon, ChatIcon, ChatRedIcon, MembershipCrownIcon, MembershipCrownWhiteIcon, NoProductsIcon, OrderIcon, OrderWhiteIcon, TilesIcon, TilesWhiteIcon } from '../../../components/SVGIcons'
import "./index.scss"

const Notifications = () => {
    const dispatch = useDispatch()
    const { notification, isLoading } = useSelector(state => state?.notification);
    const { showErrorMessage, showSuccessMessage } = SnackMessages()
    const [startApiCall, setstartAPICall] = useState(true)
    const [hasMore, setHasMore] = useState(true)
    const [bottomDrawer, setbottomDrawer] = useState({
        isOpen: false,
        position: 'bottom',
        data: {},
        type: '',
        title: '',
    })
    const [pageProps, setPageProps] = useState({
        page: 0,
        limit: 10,
    });
    const navigate = useNavigate()

    const handleCloseDrawer = () => setbottomDrawer({ isOpen: false, position: 'bottom', type: '' })

    const handleClearAll = async () => {
        const res = await dispatch(actionClearNotifications())
        if (res?.success) {
            showSuccessMessage(res?.message)
            await dispatch(actionClearNotificationList())
        } else {
            showErrorMessage(res?.message)
        }
    }

    const getNotificationIcons = (type) => {
        switch (type) {
            case "order":
                return <OrderIcon />

            case "promotion":
                return <TilesIcon />

            case "similar_product":
                return <ChatRedIcon />

            case "product":
                return <TilesIcon />

            case "membership":
                return <MembershipCrownIcon />

            default:
                return
        }
    }

    const getIconsAfterRead = (type) => {
        switch (type) {
            case "order":
                return <OrderWhiteIcon />

            case "promotion":
                return <TilesWhiteIcon />

            case "similar_product":
                return <ChatIcon />
            
            case "product":
                return <TilesWhiteIcon />
            
            case "membership":
                return <MembershipCrownWhiteIcon />

            default:
                return
        }
    }

    // infinite scroll handler
    const loadMoreData = () => {
        setPageProps({ ...pageProps, page: pageProps.page + 1 * pageProps.limit });
        setstartAPICall(true)
    }

    const NoMoreItems = () => (
        <div className='text-center d-flex align-items-center m-auto justify-content-center color-info font-semibold'>
            No More Items!
        </div>
    )

    const EmptyProducts = () => (
        <div className='text-center d-flex flex-column gap-3 align-items-center my-auto justify-content-center'>
            <NoProductsIcon />
            <Typography variant={'text-custom'} fontSize="text-22" color="color-dark02" margin="mb-1" label={"No Notifications Found!"} />
        </div>
    )

    const CustomItemListLoader = ({ viewby }) => (
        <>
            {Array.from(Array(10).keys())?.map((i) =>
                <div className='loader col-md-12 col-lg-4 col-xxl-3' id={`content-${viewby}`} key={i}>
                    <Placeholder className="placeholder-1" animation="glow">
                        <Placeholder xs={12} className="placeholder-sub-1" />
                    </Placeholder>
                </div>)}
        </>
    )

    const fetchNotifications = useCallback(async () => {
        const res = await dispatch(actionGetNotifications({ page: pageProps.page, limit: pageProps.limit }))
        if (!res?.success) {
            showErrorMessage(res?.message)
        }
        setstartAPICall(false)
    }, [dispatch, pageProps, showErrorMessage])

    const handleNotificationClick = async (id, read, type, additional_data) => {
        if (read === 0) {
            const res = await dispatch(actionNotificationMarkasRead(id))
            if (!res?.success) {
                showErrorMessage(res?.message)
            }
            await dispatch(actionUpdateNotificationCount({ total_unread: notification?.total_unread - 1 }))
        }
        if (type === "order") {
            localStorage.setItem('orders', additional_data?.order_request_number)
            navigate(`/orders?tab=${additional_data?.order_type?.charAt(0).toUpperCase() + additional_data?.order_type?.slice(1)}`)
        } else if (type === "similar_product") {
            const materials = additional_data?.materials
            const materialsArray = JSON.stringify(materials)
            localStorage.setItem('materialsArray', materialsArray);
            navigate('/similar-products')
        } else if (type === "promotion") {
            navigate(`/promotion-details/${additional_data?.id}`)
        } else if (type === "product") {
            navigate(`/products?id=${additional_data?.id}`)
        }
    }

    useEffect(() => {
        if (startApiCall) {
            fetchNotifications()
            document.title = 'Tab India customer - Notifications';
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startApiCall])

    useEffect(() => {
        if (Number(notification?.total) !== Number(notification?.data?.length)) {
            setHasMore(true)
        } else {
            setHasMore(false)
        }
    }, [notification, hasMore])

    useEffect(() => {
        if ('Notification' in window) {
            Notification.requestPermission()
                .then(permission => {
                    console.log('Notification permission:', permission);
                })
                .catch(error => {
                    console.error('Error requesting notification permission:', error);
                });
        }
    }, []);

    return (
        <div className='notification flex-fill-row'>
            <Drawer data={bottomDrawer} variant={"chatWidget"}
                children={<ChatPopUp type={bottomDrawer?.type} handleCloseDrawer={handleCloseDrawer} />}
                handleCloseDrawer={handleCloseDrawer} />
            <Row className="mb-3 mb-xl-4">
                <Col xs={12} className="d-flex align-items-center gap-3 justify-content-between">
                    <div className="d-flex flex-row gap-3 align-items-center">
                        <Button onClick={() => navigate(-1)} variant="button-back" icon={<BackButtonIcon />} />
                        <Typography variant={'heading4'} margin="mb-0" label="Notification" />
                    </div>
                    <div className="d-flex flex-row gap-3 align-items-center">
                        <Button label="Clear all" disabled={notification.data?.length === 0} onClick={handleClearAll} variant="btn-link" type="color-info" />
                    </div>
                </Col>
            </Row>
            <div className='notification-body flex-fill-row w-100 p-3' id={'content-Notification'}>
                {!!notification.data?.length ? <div className='row items-list h-100' id={'scrollerRefNotification'} >
                    <div className="col-12 h-100">
                        <Scroller
                            hasMore={hasMore}
                            className="flex-wrap"
                            scrollableTarget={'scrollerRefNotification'}
                            endMessage={isLoading ? <CustomItemListLoader key={0} /> : <NoMoreItems />}
                            dataLength={notification.data?.length > 0 && notification.data.length}
                            loader={<CustomItemListLoader key={0} />}
                            loadFunc={loadMoreData}>
                            {notification?.data?.length && notification?.data?.map((item) => <Col key={item?.id} xs={12}>
                                <div className='mb-2 mb-md-3' onClick={() => handleNotificationClick(item?.id, item?.is_read, item?.type, item?.additional_data)}>
                                    <Card variant={"off-white-notification-card"} className={Boolean(item?.is_read) ? "mark" : ""}>
                                        <div className='d-flex align-items-center'>
                                            <Badge bg={Boolean(item?.is_read) ? "med-gray" : "info"} className='notification-badge'>
                                                {Boolean(item?.is_read) ? getIconsAfterRead(item?.type) : getNotificationIcons(item?.type)}
                                            </Badge>
                                            <div className='d-flex flex-column mx-3'>
                                                <Typography variant={"text-custom"} fontSize={'text-18'} fontWeight={'font-semibold'} color={item?.is_read ? "color-gray03" : "color-dark01"} margin="mb-0" label={item?.notification} />
                                                {item?.additional_data?.image_url && <Avatar variant={'cart-product-image'} bsPrefix src={`${item?.additional_data?.url}/original${item?.additional_data?.image_url}`} />}
                                                <Typography label={moment(item?.created_at)?.format("h:mm A, DD MMM YYYY")} margin={"mb-0"} variant={"body3"} color={item?.is_read ? "color-gray" : "color-med-gray"} />
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            </Col>)}
                        </Scroller>
                    </div>
                </div> : !isLoading && <EmptyProducts />}
            </div>
        </div>
    )
}

export default React.memo(Notifications); 