import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import { BackButtonIcon } from '../../../../components/SVGIcons'
import { Card, Avatar, Button, Typography, Range } from '../../../../components'
import "./index.scss";

export const SettingsHead = ({ type, user }) => {
    const navigate = useNavigate()
    const [percentage, setPercentage] = useState(0)

    const loyalityAmount = (amt) => {
        const point = Number(amt)
        const maxPoint = 3000000;
        const stepPoint3 = 1000000;
        const stepPoint2 = 500000;
        const stepPoint1 = 250000;
        if (point >= maxPoint) {
            setPercentage((point / maxPoint) * 100)
        } else if (point < maxPoint && point >= stepPoint3) {
            const thirdStep = ((point - stepPoint3) / (maxPoint - stepPoint3)) * 100
            setPercentage(((thirdStep + 200) / 300) * 100)
        } else if (point < stepPoint3 && point >= stepPoint2) {
            const secondStep = ((point - stepPoint2) / (stepPoint2)) * 100
            setPercentage(((secondStep + 100) / 300) * 100)
        } else if (point < stepPoint2 && point > stepPoint1) {
            const firstStep = ((point - stepPoint1) / (stepPoint1)) * 100
            setPercentage((firstStep / 300) * 100)
        } else {
            setPercentage(0)
        }
    }

    const getPoints = (amt) => {
        const roundedNum = (amt / 1000000) //Converting to million
        const formattedNum = roundedNum.toFixed(2);
        return formattedNum
    }

    useEffect(() => {
        if (user?.loyality_amount !== undefined) {
            loyalityAmount(user?.loyality_amount)
        }
    }, [user?.loyality_amount])

    return (
        <div className='profile-head'>
            <Card variant={'profile-head-card'}>
                <div className={`profile-head-type ${type}`}>
                    <div className='d-flex align-items-center justify-content-between mb-3 mb-xl-2 profile-back'>
                        <Button icon={<BackButtonIcon />} onClick={() => navigate(-1)}  variant="button-back" />
                    </div>
                    <div className='row flex-column flex-lg-row align-items-center justify-content-between px-md-2 px-xl-5 mb-0 mb-lg-2 mt-0 mt-lg-2'>
                        <div className='col-12 col-lg-5 col-xl-6 d-flex align-items-center justify-content-center justify-content-lg-start gap-2 gap-xl-3 mb-3 mb-lg-0 flex-column flex-md-row flex-lg-column flex-xl-row text-center text-md-start text-lg-center text-xl-start'>
                            <Avatar variant={'profile-image'} membership={`${type}`} bsPrefix src={user?.profileImage || "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200"} />
                            <div className='d-inline-flex flex-column'>
                                <Typography variant={'profile-name'} color="color-white" margin="mb-1" label={user?.username} />
                                <Typography variant={'profile-company'} color="color-secondary" margin="mb-1" label={user?.company} />
                            </div>
                        </div>
                        <div className='col-12 col-lg-7 col-xl-6 col-xxl-5 d-flex flex-column align-items-center gap-3 text-center'>
                            <Range variant="three-point-range" value={[percentage > 100 ? 100 : percentage]} point={[getPoints(user?.loyality_amount)]} range={[0, 100]} />
                        </div>
                    </div>
                </div>
                        
            </Card>
        </div>
    )
}
