import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Col, Form, InputGroup, Row } from 'react-bootstrap'
import { SnackMessages } from '../../../../../components/Toast'
import { actionCreateSubUser } from '../../../../../actions/Settings'
import { Button, Switch, Typography } from '../../../../../components'
import { EyeCloseIcon, EyeOpenIcon } from '../../../../../components/SVGIcons'
import "./index.scss"

const CreateSubUserModal = ({ handleCloseModal }) => {
    const dispatch = useDispatch()
    const { showSuccessMessage, showErrorMessage } = SnackMessages()
    const [showPassword, setShowPassword] = useState(false)
    const [errorMessage, setErrorMessage] = useState({ name: "", email: "", password: "" })
    const [payload, setPayload] = useState({ name: "", email: "", password: "", permissions: [1, 2, 3, 4, 5, 6] })
    const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const [subUserPermissions, setSubUserPermissions] = useState([
        { id: 1, label: "Can Place Order Request", checked: true },
        { id: 2, label: "Can Track My Order", checked: true },
        { id: 3, label: "Can Access Documents", checked: true },
        { id: 4, label: "Can Add To Cart", checked: true },
        { id: 5, label: "Can See Products", checked: true },
        { id: 6, label: "Can Edit My Profile", checked: true }
    ])

    const checkValidation = () => {
        if (!emailPattern.test(payload?.email)) {
            setErrorMessage({ email: 'Enter a vaild email' })
        } else if (!passwordRegex.test(payload?.password)) {
            setErrorMessage({ password: 'Password must include uppercase, lowercase, numeric, and special characters' })
        } else if (payload?.name?.length < 1) {
            setErrorMessage({ name: "Name field is required" })
        } else {
            setErrorMessage("")
        }
    }

    const handleChangePasswordInput = (e) => {
        const { name, value } = e.target
        setPayload((prevState) => {
            return {
                ...prevState,
                [name]: value,
            };
        });
        checkValidation()
    }

    const handleSubUserPermissions = (event, permissionId) => {
        const updatedPermissions = subUserPermissions.map(permission => {
            if (permission.id === permissionId) {
                return {
                    ...permission,
                    checked: event.target.checked
                };
            }
            return permission;
        });
        setSubUserPermissions(updatedPermissions);
        const selectedPermission = subUserPermissions?.find(permission => permission?.id === permissionId)
        if (event?.target?.checked) {
            if (!payload?.permissions?.includes(permissionId)) {
                setPayload({ ...payload, permissions: [...payload?.permissions, selectedPermission?.id] });
            }
        } else {
            const updatedPermissions = payload.permissions.filter(item => item !== permissionId);
            setPayload(prevPayload => ({
                ...prevPayload,
                permissions: updatedPermissions
            }));
        }
    }

    const toggleShowandHidePassword = () => setShowPassword(!showPassword)

    const handleCreateSubUser = async () => {
        checkValidation()
        if (!errorMessage?.email?.length && !errorMessage?.name?.length && !errorMessage?.password?.length) {
            const res = await dispatch(actionCreateSubUser(payload))
            if (res?.success) {
                showSuccessMessage(res?.message)
                handleCloseModal()
            } else {
                showErrorMessage(res?.message)
            }
        }
    }

    return (
        <div className='create-subuser p-3 p-lg-4'>
            <Typography variant={"text-custom"} fontSize="text-18" fontWeight={'font-semibold'} color="color-dark01" margin="mb-3 mt-2" label={"Create Sub User"} />
            <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <InputGroup className='form-group-labeled'>
                        <Form.Control type="email"
                            name='email'
                            onChange={handleChangePasswordInput}
                            className="neu-form form-md"
                            placeholder='Enter email address' />
                    </InputGroup>
                    <div className='error'>{!emailPattern.test(payload?.email) ? errorMessage?.email : ""}</div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <InputGroup className='form-group-labeled'>
                        <Form.Control type="text"
                            name="name"
                            onChange={handleChangePasswordInput}
                            className="neu-form form-md"
                            placeholder='Enter name' />
                    </InputGroup>
                    <div className='error'>{payload?.name < 1 ? errorMessage?.name : ""}</div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <InputGroup className='form-group-labeled'>
                        <Form.Control type={showPassword ? "text" : "password"}
                            name="password"
                            onChange={handleChangePasswordInput}
                            className="neu-form form-md"
                            placeholder='Enter password' />
                        {showPassword ? <span className="passwordAction d-flex align-items-center justify-content-center eye-icon" onClick={toggleShowandHidePassword}><EyeOpenIcon /></span> : <span className="passwordAction d-flex align-items-center justify-content-center input-group-text password-icon" onClick={toggleShowandHidePassword}><EyeCloseIcon /></span>}
                    </InputGroup>
                    <div className='error'>{!passwordRegex.test(payload?.password) ? errorMessage?.password : ""}</div>
                </Form.Group>
                <div>
                    {subUserPermissions?.map((permission) => <div className='d-flex flex-row align-items-center mb-2 gap-2'>
                        <Switch variant='settings-handler' name={permission?.label} handleChange={(event) => handleSubUserPermissions(event, permission?.id)} label={permission?.checked ? "ON" : "OFF"} checked={permission?.checked} />
                        <Typography variant={"text-custom"} fontSize="text-14" fontWeight={'font-semibold'} color={permission?.checked ? "color-primary" : "color-gray"} margin="mb-0" label={permission?.label} />
                    </div>)}
                </div>
            </Form>
            <Row className='mb-2'>
                <Col xs={6} className={'mt-2'}>
                    <Button label="Cancel" onClick={handleCloseModal} className={'w-100'} variant="secondary-shadow" />
                </Col>
                <Col xs={6} className={'mt-2'}>
                    <Button label="Update" onClick={handleCreateSubUser} className={'w-100'} variant="primary-shadow" />
                </Col>
            </Row>
        </div>
    )
}

export default CreateSubUserModal