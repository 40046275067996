import React from 'react'
import { Form } from 'react-bootstrap'
import "./index.scss"

export const CustomRadio = ({ label, checked }) => {
    return (
        <div className='custom-radio'>
            <Form.Check
                type={'radio'}
                checked={checked}
                id={`default-radio`}
                label={label}
            />
        </div>
    )
}
