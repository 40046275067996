import React from 'react'
import { Offcanvas } from 'react-bootstrap';
import "./index.scss"

export const Drawer = ({ children, handleCloseDrawer, data, variant }) => {

    const renderDrawer = () => {
        const handleClose = () => handleCloseDrawer();
        return (<Offcanvas keyboard={false} className={variant}
            enforceFocus={false} backdrop={false}
            show={data.isOpen} onHide={handleClose} name={data?.position} placement={data?.position} >
            {data?.title && <Offcanvas.Header closeButton>
                <Offcanvas.Title>{data?.title || ''}</Offcanvas.Title>
            </Offcanvas.Header>}
            <Offcanvas.Body>
                {children || ''}
            </Offcanvas.Body>
        </Offcanvas>);
    }

    return (
        <div className='drawer'>
            {renderDrawer()}
        </div>
    )
}
