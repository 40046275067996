import React from 'react'
import Rating from "react-rating";
import { StarIconEmpty, StarIconFull } from '../SVGIcons';


export const CustomRatings = ({ rating, onClick, readonly }) => {
    return (
        <div>
            <Rating
                emptySymbol={<StarIconEmpty />}
                fullSymbol={<StarIconFull />}
                onClick={onClick}
                readonly={readonly}
                initialRating={Number(rating)}
                fractions={2}
            />
        </div>
    )
}
