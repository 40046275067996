import { GET_NOTIFICATIONS_BEGINS, GET_NOTIFICATIONS_SUCCESS, GET_NOTIFICATIONS_FAILURE, NOTIFICATION_MARK_AS_READ_BEGINS, NOTIFICATION_MARK_AS_READ_SUCCESS, NOTIFICATION_MARK_AS_READ_FAILURE, DELETE_NOTIFICATIONS_BEGINS, DELETE_NOTIFICATIONS_SUCCESS, DELETE_NOTIFICATIONS_FAILURE, UPDATE_UNREAD_NOTIFICATION_COUNT_SUCCESS, CLEAR_NOTIFICATION_LIST_SUCCESS } from "../../constants";
import api from "../../utils/axios";

const getNotificationsBegins = () => ({ type: GET_NOTIFICATIONS_BEGINS });
const getNotificationsSuccess = (data) => ({ type: GET_NOTIFICATIONS_SUCCESS, data });
const getNotificationsFailure = () => ({ type: GET_NOTIFICATIONS_FAILURE });

const notificationMarkasReadBegins = () => ({ type: NOTIFICATION_MARK_AS_READ_BEGINS })
const notificationMarkasReadSuccess = (data) => ({ type: NOTIFICATION_MARK_AS_READ_SUCCESS, data })
const notificationMarkasReadFailure = () => ({ type: NOTIFICATION_MARK_AS_READ_FAILURE })

const clearNotificationsBegins = () => ({ type: DELETE_NOTIFICATIONS_BEGINS })
const clearNotificationsSuccess = (data) => ({ type: DELETE_NOTIFICATIONS_SUCCESS, data })
const clearNotificationsFailure = () => ({ type: DELETE_NOTIFICATIONS_FAILURE })

const updateNotificationCount = (data) => ({ type: UPDATE_UNREAD_NOTIFICATION_COUNT_SUCCESS, data})

const clearNotificationList = (data) => ({ type: CLEAR_NOTIFICATION_LIST_SUCCESS, data})

//GET NOTIFICATIONS
export const actionGetNotifications = ({ page, limit }) => async (dispatch) => {
    dispatch(getNotificationsBegins());
    try {
        const res = await api.get('/customer/notifications', { params: { limit, offset: page } });
        if (res.status === 200) {
            const response = res.data.data || { response: { data: [], total: 0, total_unread: 0 } }
            dispatch(getNotificationsSuccess(response))
            return { success: true, message: 'success', data: response }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(getNotificationsFailure());
        return { success: false, message: message }
    }
}


// NOTIFICATION MARK AS READ
export const actionNotificationMarkasRead = (id) => async (dispatch) => {
    dispatch(notificationMarkasReadBegins());
    try {
        const res = await api.put(`customer/notifications/${id}/read`);
        if (res.status === 200) {
            const { message } = res?.data;
            dispatch(notificationMarkasReadSuccess())
            return { success: true, message: message }
        }
    } catch (error) {
        const { message } = error?.response || { message: 'Something went Wrong' };
        dispatch(notificationMarkasReadFailure());
        return { success: false, message: message }
    }
}

// CLEAR NOTIFICATIONS
export const actionClearNotifications = () => async (dispatch) => {
    dispatch(clearNotificationsBegins());
    try {
        const res = await api.delete("customer/notifications/clear");
        if (res.status === 200) {
            const { message } = res?.data;
            dispatch(clearNotificationsSuccess())
            return { success: true, message: message }
        }
    } catch (error) {
        const { message } = error?.response || { message: 'Something went Wrong' };
        dispatch(clearNotificationsFailure());
        return { success: false, message: message }
    }
}

// UPDATE CART COUNT
export const actionUpdateNotificationCount = (data) => async (dispatch) => dispatch(updateNotificationCount(data))

// CLEAR NOTIFICATIONS
export const actionClearNotificationList = (data) => async (dispatch) => dispatch(clearNotificationList())