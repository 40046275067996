import {
    CLEAR_WISHLIST_SUCCESS,
    GET_WISH_LIST_BEGINS, GET_WISH_LIST_FAILURE, GET_WISH_LIST_SUCCESS, REMOVE_ALL_FROM_WISH_LIST_BEGINS,
    REMOVE_ALL_FROM_WISH_LIST_FAILURE, REMOVE_ALL_FROM_WISH_LIST_SUCCESS, UPDATE_WISH_LIST_SUCCESS
} from '../constants'

const INITIAL_STATE = {
    isLoading: false,
    wishlist: {
        data: [],
        total: 0,
        totalPages: 0,
    },
    isWishlistLoad: false,
}

const concatMultiplearrays = (array1, array2) => {
    const uniq = {};
    const sum = [...array1, ...array2]
    const unniqueElements = sum?.filter(obj => !uniq[obj?.id] && (uniq[obj?.id] = true));
    return [...unniqueElements] || []
}


const wishlistReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_WISH_LIST_BEGINS:
            return { ...state, isLoading: true };
        case GET_WISH_LIST_SUCCESS:
            return {
                ...state, isLoading: false, wishlist: {
                    data: concatMultiplearrays(state?.wishlist?.data || [], action.data.data),
                   total: action.data.total,
                }
            }
        case GET_WISH_LIST_FAILURE:
            return { ...state, isLoading: false };

        case UPDATE_WISH_LIST_SUCCESS:
            return { ...state, wishlist: { ...action.data } };

        case REMOVE_ALL_FROM_WISH_LIST_BEGINS:
            return { ...state, isLoading: true }

        case REMOVE_ALL_FROM_WISH_LIST_SUCCESS:
            return {
                ...state, wishlist: {
                    data: [],
                    total: 0,
                    totalPages: 0,
                }, isLoading: false
            }

        case REMOVE_ALL_FROM_WISH_LIST_FAILURE:
            return { ...state, isLoading: false }

        case CLEAR_WISHLIST_SUCCESS:
            return {
                ...state, wishlist: {
                    data: [],
                    total: 0,
                    totalPages: 0,
                }
            }

        default:
            return state
    }
}

export default wishlistReducer