import React from 'react'
import { Form } from 'react-bootstrap'
import "./index.scss"

export default function CustomCheckbox({ variant, label, id, name, onChange, checked }) {

    const renderCheckBox = () => {
        switch (variant) {
            case 'labelled':
                return (<Form.Group className="labelled" controlId={id}>
                    <Form.Check checked={Boolean(checked)} onChange={(e) => onChange(e, name)} type="checkbox" name={name} label={label} />
                </Form.Group>)

            case 'address':
                return (<Form.Group className="address-check" controlId={id}>
                    <Form.Check checked={Boolean(checked)} onChange={(e) => onChange(e, name)} type="checkbox" name={name} label={label} />
                </Form.Group>)

            default:
                return (<Form.Group controlId={id}>
                    <Form.Check checked={checked} onChange={(e) => onChange(e, name)} type="checkbox" name={name} label={label} />
                </Form.Group>)
        }

    }


    return (
        <div className='custom-checkbox'> {renderCheckBox()}</div>
    )
}
