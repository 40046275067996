import Form from 'react-bootstrap/Form';
import "./index.scss"

export const CustomSwitch = ({ checked, handleChange, label, variant, disabled, name }) => {


    const renderSwitch = () => {
        switch (variant) {
            case 'settings-handler':
                return (
                    <Form className='d-flex align-items-center'>
                        <Form.Check
                            type="switch"
                            defaultChecked={checked}
                            onChange={handleChange}
                            id={`custom-switch-${name}`}
                            className={'switch-lg'}
                            label={label}
                            name={name}
                            disabled={disabled}
                        />
                    </Form>)

            default:
                return (
                    <Form>
                        <Form.Check
                            type="switch"
                            checked={checked}
                            onChange={handleChange}
                            id={`custom-switch-${name}`}
                            label={label}
                            disabled={disabled}
                            name={name}
                        />
                    </Form>
                )
        }

    }

    return (
        <>
            {renderSwitch()}
        </>
    );
}

