import { db } from '../../firebase'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Form, Row } from 'react-bootstrap'
import { Avatar, Button, Card, Typography } from '..'
import { collection, doc, getDocs, query, setDoc, updateDoc, where } from 'firebase/firestore'
import { CloseChatPopUpIcon, PaperPlaneIcon } from '../SVGIcons'
import "./index.scss"
import moment from 'moment'
import { Avatars } from '../Avatars'

const ChatPopUp = ({ chatHistory, handleCloseDrawer, fetchPost, customer, getUserChat, chat, referenceId, chatLoading }) => {
    const { user } = useSelector((state) => state.authReducer)
    const containerRef = useRef(null);
    const [message, setMessage] = useState("")
    const sender = user

    const handleSubmit = async (e) => {
        e.preventDefault();
        const email = user?.email;
        // const orderId = chat[chat.length - 1]?.orderId;
        e.preventDefault()
        if (message.trim() === "") {
            // alert("Enter valid message");
            return;
        }
        const q = query(collection(db, 'users'), where('email', '==', email));
        const querySnapShot = await getDocs(q);
        const queryData = querySnapShot.docs.map((e) => ({
            id: e.id
        }));

        // const res = await doc(db, `users/${e.id}`).set({ capital: true })
        queryData.map(async (e) => {
            await updateDoc(doc(db, `users/${e.id}`), {
                lastMessage: message,
                lastMessageTime: new Date(),
                isSeen: true,

            });
            await setDoc(doc(db, `users/${e.id}/messages`, new Date().toISOString()), {
                message: message,
                orderId: (referenceId) ? referenceId?.toString() : "",
                createdOn: new Date(),
                senderId: sender?.email,
                senderName: sender?.username,
                profilePic: sender.profileImage,
            });
            scrollToBottom()
        })
        getUserChat(customer)
        fetchPost()
        setMessage("")
    }

    const getDateandTime = (timestamp) => {
        const date = new Date(timestamp.seconds * 1000 + Math.round(timestamp.nanoseconds / 1e6));
        return moment(date.toISOString()).format('DD-MM-YYYY hh:mm A')
    }

    // Function to scroll to the bottom of the container
    const scrollToBottom = () => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom()
    }, [])


    return (
        <div className='d-flex flex-column h-100'>
            <div className='d-flex justify-content-between'>
                <div className='d-flex align-items-center chat-header gap-2'>
                    <Avatar variant={'header-pro-thumb'} bsPrefix src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200" />
                    <div className='d-flex flex-column ps-2 header-pro-title'>
                        <Typography variant={"text-custom"} fontSize={'text-16 text-truncate'} fontWeight={'font-semibold'} color={'color-gray03'} margin="mb-0" label={"TAB Surfaces"} />
                    </div>
                </div>
                <div onClick={handleCloseDrawer} className="d-flex flex-row align-items-center ms-2">
                    <Button icon={<CloseChatPopUpIcon />} variant="link-secondary" className={'px-0'} />
                </div>
            </div>
            <hr />
            {!chatLoading ? <div ref={containerRef} className='chat-body'>
                {chat?.length > 0 ? chat?.map((msg) => <>
                    {msg?.senderId === user?.email ? <div className='chat-each chat-me'>
                        <div className='d-flex flex-row gap-2 justify-content-end'>
                            <Card>
                                <Typography variant={"text-custom"} fontSize={'text-12'} fontWeight={'font-semibold'} color={'color-primary'} margin="mb-0" label={msg?.orderId ? `#${msg?.orderId}` : ""} />
                                <Typography variant={"text-custom"} fontSize={'text-14'} fontWeight={'font-medium'} color={'color-black'} margin="mb-0" label={msg?.message} />
                                <Typography variant={"text-custom"} fontSize={'text-12'} fontWeight={'font-medium'} color={'color-black'} margin="mb-0" label={getDateandTime(msg?.createdOn)} />
                            </Card>
                            <Avatar variant={'header-pro-thumb'} bsPrefix src={msg?.profilePic || "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200"} />
                        </div>
                    </div> :
                        <div className='chat-each'>
                            <div className='d-flex flex-row gap-2 justify-content-start'>
                                <Avatar variant={'header-pro-thumb'} bsPrefix src={msg?.profilePic || "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200"} />
                                <Card>
                                    <Typography variant={"text-custom"} fontSize={'text-12'} fontWeight={'font-semibold'} color={'color-primary'} margin="mb-0" label={msg?.orderId ? `#${msg?.orderId}` : ""} />
                                    <Typography variant={"text-custom"} fontSize={'text-14'} fontWeight={'font-medium'} color={'color-black'} margin="mb-0" label={msg?.message} />
                                    <Typography variant={"text-custom"} fontSize={'text-14'} fontWeight={'font-medium'} color={'color-black'} margin="mb-0" label={getDateandTime(msg?.createdOn)} />
                                </Card>
                            </div>
                        </div>}
                </>) : <>No Chats found</>}
            </div> : <div className='chat-loading d-flex align-items-center justify-content-center h-100'><Avatars src="/images/loader.gif" variant={"gif-loader"} /></div>}
            <div className='mt-3'>
                <Row className='gx-2'>
                    <Col xs={10} sm={10} className={'flex-sm-fill'}>
                        <div className='chat-form-box mb-md-2'>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-0" controlId="formBasicEmail">
                                    <Form.Control onChange={(e) => setMessage(e?.target?.value)} value={message} type="text" placeholder="" className={'neu-form'} />
                                </Form.Group>
                            </Form>
                        </div>
                    </Col>
                    <Col xs={2} sm={'auto'}>
                        <Button onClick={handleSubmit} icon={<PaperPlaneIcon />} variant="primary-shadow" className={'px-2 py-2'} />
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default ChatPopUp