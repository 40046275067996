import React from 'react'
import "./index.scss"

export const Typography = ({ variant, label, color, infoSymbol, margin, sublabel, subcolor, fontSize, fontWeight,subFontSize, sublabel2, link, linkText, target ,subcolor2}) => {
    const renderTypographyComponent = () => {
        switch (variant) {
            case 'title':
                return <p className={`font-secondary text-24 ${color || ''} ${margin || ''}`}>{label}</p>
            case 'subtitle':
                return <p className={`text-18 font-bold ${color || ''} ${margin || ''}`}>{label}<span className='color-primary'>{sublabel}</span>{sublabel2 || ''}</p>
            
            case 'subtitle-v1':
                return <p className={`text-18 font-bold ${color || ''} ${margin || ''}`}>{label} <span className='text-20 color-primary'>{sublabel}</span></p>

            case 'welcomeTitle':
                return <p className={`text-35 font-bold ${color || ''} ${margin || ''}`}><span className='text-18 font-semibold'>{label}</span><br/>{sublabel}</p>
                
            case 'heading1':
                return <h1 className={`${fontSize || 'text-34'} font-bold ${color || ''} ${margin || ''}`}>{label}</h1>

            case 'heading2':
                return <h2 className={`${fontSize || 'text-24'} font-bold ${color || ''} ${margin || ''}`}>{label}</h2>

            case 'heading3':
                return <h3 className={`${fontSize || 'text-18'} font-bold ${color || ''} ${margin || ''}`}>{label}</h3>

            case 'heading4':
                return <h4 className={`${fontSize || 'text-18'} font-semibold ${color || ''} ${margin || ''}`}>{label}</h4>

            case 'heading5':
                return <h5 className={`${fontSize || 'text-16'} font-bold ${color || ''} ${margin || ''}`}>{label}</h5>

            case 'heading6':
                return <h6 className={`${fontSize || 'text-14'} font-bold ${color || ''} ${margin || ''}`}>{label}</h6>

            case 'subtitle1':
                return <p className={`text-16 font-semibold ${color || ''} ${margin || ''}`}>{label}</p>

            case 'subtitle2':
                return <p className={`text-14 font-semibold ${color || ''} ${margin || ''}`}>{label}</p>

            case 'body1':
                return <p className={`text-16 font-medium ${color || ''} ${margin || ''}`}>{label} {infoSymbol ?  <span className='color-info mx-2'>{infoSymbol}</span> :''} {sublabel}</p>

            case 'body2':
                return <p className={`text-15 font-medium ${color || ''} ${margin || ''}`}>{label} {infoSymbol ?  <span className='color-info mx-2'>{infoSymbol}</span> :''} {sublabel}</p>
            
            case 'body3':
            
                return <p className={`text-14 font-medium ${color || ''} ${margin || ''}`}>{label} {infoSymbol ?  <span className='color-info mx-2'>{infoSymbol}</span> :''} {sublabel}</p>

            case 'body4':
                return <p className={`text-13 font-medium ${color || ''} ${margin || ''}`}>{label} {infoSymbol ?  <span className='color-info mx-2'>{infoSymbol}</span> :''} {sublabel}</p>

            case 'price01':
                return <p className={`text-16 font-semibold color-primary ${color || ''} ${margin || ''}`}>{label}</p>
            
            case 'copyright':
                return <p className={`text-11 m-0 ${color || ''} ${margin || ''}`}>{label}</p>
            
            case 'logo-title':
                return <p className={`text-16 font-bold logo-title ${color || ''} ${margin || ''}`}>{label}</p>

            case 'profile-name':
                return <h4 className={`text-20 font-bold ${color || ''} ${margin || ''}`}>{label}</h4>

            case 'profile-company':
                return <p className={`text-15 font-semibold ${color || ''} ${margin || ''}`}>{label}</p>

            case 'text-custom':
                return <p className={`${fontSize || ''} ${fontWeight || ''} ${color || ''} ${margin || ''}`}>{label} {infoSymbol ? <span className='color-info mx-2'>{infoSymbol}</span> :''} {sublabel ? <span className={`${subcolor || ''} ${subFontSize || ''}`}>{sublabel}</span> :''} {sublabel2 ?  <span className={`${subcolor2 || ''}`}>{sublabel2}</span> :''}</p>
      
                case 'text-custom-link':
                    return <p className={`${fontSize || ''} ${fontWeight || ''} ${color || ''} ${margin || ''}`}>{label} {infoSymbol ?  <span className='color-info mx-2'>{infoSymbol}</span> :''} {link ? <a className='color-primary' href={link} target={target}>{linkText}</a> :''} {sublabel ?  <span className={`${subcolor || ''}`}>{sublabel}</span> :''}</p>
 
    
            default:
                return <p>{label}</p>
        }
    }


    return (
        <div className='typography'>
            {renderTypographyComponent()}
        </div>
    )
}

