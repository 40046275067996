import React, { useEffect, useState } from 'react'
import { Badge, Col, Nav, Row, Tab, Tabs } from 'react-bootstrap'
import "./index.scss"

const CustomTabs = ({ data, selected, variant, customComponent, onSelect, onChange }) => {
    const [key, setKey] = useState(selected);

    useEffect(() => {
        setKey(selected);
    }, [selected])


    const renderTabs = () => {
        switch (variant) {
            case 'tab-with-button':
                return (
                    <div className='tab-button-type'>
                        <Tab.Container id="left-tabs-example" activeKey={key}
                            onSelect={(k) => onSelect(k)}>
                            <Row className='align-items-center'>
                                <Col xl={6}>
                                    <Nav variant="pills" className="flex-row mw my-2">
                                        {data?.length &&
                                            data?.map((tab, index) => <Nav.Item key={index}>
                                                <Nav.Link eventKey={tab?.title}>{tab?.title} <Badge>{tab?.count}</Badge> </Nav.Link>
                                            </Nav.Item>
                                            )}
                                    </Nav>
                                </Col>
                                <Col xl={6} className='mt-2 mt-xl-0'>
                                    {customComponent && customComponent}
                                </Col>

                            </Row>
                            <Row className='flex-fill-row'>
                                <Col sm={12} className='flex-fill-column'>
                                    <Tab.Content className='flex-fill-row'>
                                        {data?.length &&
                                            data?.map((tab, index) => <Tab.Pane key={index} eventKey={tab?.title}>
                                                {tab.children}
                                            </Tab.Pane>)}

                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>)
            case 'tab-with-border':
                return (
                    <div className='tab-with-border gap-3 mt-3 d-flex flex-column'>
                        <Tabs defaultActiveKey="profile"
                            id="tab-with-border"
                            activeKey={selected}
                            onSelect={(selected) => onChange(selected)}
                            className="mb-0">
                            {data?.length &&
                                data?.map((tab, index) =>
                                    <Tab eventKey={tab?.title}
                                        key={index}
                                        title={tab?.title}
                                        disabled={tab?.disabled}>
                                        {tab?.children}
                                    </Tab>)}
                        </Tabs>
                    </div>)
            case 'tab-with-button-vertical':
                return (
                    <div className='tab-with-button-vertical row'>
                        <Tabs defaultActiveKey="profile"
                            id="tab-with-border"
                            activeKey={key}
                            onSelect={(k) => onSelect(k)}
                            className="col-6 px-3 border-0">
                            {data?.length &&
                                data?.map((tab, index) =>
                                    <Tab eventKey={tab?.title}
                                        key={index}
                                        title={tab?.title}
                                        disabled={tab?.disabled}>
                                        {tab?.children}
                                    </Tab>)}
                        </Tabs>
                    </div>)
            default:
                return (<Tabs
                    defaultActiveKey="profile"
                    id="default-tab"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3">
                    {data?.length &&
                        data?.map((tab, index) =>
                            <Tab eventKey={tab?.title}
                                key={index}
                                title={tab?.title}
                                disabled={tab?.disabled}>
                                {tab?.children}
                            </Tab>)}
                </Tabs>)
        }
    }



    return (
        <div className='tabs'>
            {renderTabs()}
        </div>
    )
}

export default CustomTabs