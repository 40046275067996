import React from 'react'
import Card from "react-bootstrap/Card";
import './index.scss';

export const Cards = ({ variant, children, className, parentClass }) => {

    const renderCardComponent = () => {
        switch (variant) {
            case 'off-white-card':
                return (
                    <Card>
                        <Card.Body className={className}>{children}</Card.Body>
                    </Card>)

            case 'login-card':
                return (
                    <Card className='login-card'>
                        <Card.Body>{children}</Card.Body>
                    </Card>)
            
            case 'profile-head-card':
                return (
                    <Card className='profile-head-card'>
                        <Card.Body>{children}</Card.Body>
                    </Card>)

            case 'profile-info-card':
                return (
                    <Card className='profile-info-card'>
                        <Card.Body>{children}</Card.Body>
                    </Card>)

            case 'single-product-card':
                return (
                    <Card className='single-product-card'>
                        <Card.Body>{children}</Card.Body>
                    </Card>)
            case 'off-white-notification-card':
                return (
                    <Card  className={className}>
                        <Card.Body>{children}</Card.Body>
                    </Card>)    


            default:
                return (

                    <Card className={parentClass}>
                        <Card.Body className={className}>{children}</Card.Body>
                    </Card>)
        }
    }

    return (
        <div className='cards'>
            {renderCardComponent()}

        </div>
    )
}
