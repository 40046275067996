import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import CreateSubUserModal from '../../Modals/CreateSubUser'
import { Col, Row, useAccordionButton } from 'react-bootstrap'
import { SnackMessages } from '../../../../../components/Toast'
import { CaretRightIcon } from '../../../../../components/SVGIcons'
import { actionUpdateSubUserStatus } from '../../../../../actions/Settings'
import { Accordion, Button, Dialogue, Switch, Typography } from '../../../../../components'
import "./index.scss"

export const UserManagementTab = React.memo(({ data }) => {
    const dispatch = useDispatch()
    const { showErrorMessage, showSuccessMessage } = SnackMessages()
    const [activeKey, setActiveKey] = useState(-1);
    const [selectedSubUser, setSelectedSubUser] = useState({})
    const [modalShow, setModalShow] = useState({
        isOpen: false,
        type: '',
        size: '',
        children: null,
        className: ''
    })

    const handleAccordionOpen = (subUser) => {
        const transformedArray = [
            { id: 1, label: "Can Place Order Request", checked: false },
            { id: 2, label: "Can Track My Order", checked: false },
            { id: 3, label: "Can Access Documents", checked: false },
            { id: 4, label: "Can Add To Cart", checked: false },
            { id: 5, label: "Can See Products", checked: false },
            { id: 6, label: "Can Edit My Profile", checked: false }
        ].map(item => {
            item.checked = subUser?.sub_user_permissions.includes(item.id);
            return item;
        });
        setSelectedSubUser({ ...subUser, permissions: transformedArray })
    }
    const options = data?.map((subUser, index) => ({
        key: subUser?.id,
        id: subUser?.id,
        titleComponent: <HeaderComponent eventKey={index} activeKey={activeKey} setActiveKey={setActiveKey} subUser={subUser} setSelectedSubUser={setSelectedSubUser} handleAccordionOpen={handleAccordionOpen} dispatch={dispatch} showErrorMessage={showErrorMessage} showSuccessMessage={showSuccessMessage} />,
        bodyComponent: <BodyComponent subUser={subUser} selectedSubUser={selectedSubUser} dispatch={dispatch} showErrorMessage={showErrorMessage} showSuccessMessage={showSuccessMessage} />
    }))
    const handleCloseModal = () => setModalShow({ ...modalShow, isOpen: false })

    const handleCreateSubUserModal = () => {
        setModalShow({ ...modalShow, isOpen: true, size: 'md', className: 'modal-secondary', children: <CreateSubUserModal handleCloseModal={handleCloseModal} /> })
    }

    return (
        <div className='user-management-tab'>
            <Dialogue modalShow={modalShow.isOpen}
                size={modalShow.size}
                handleCloseModal={() => setModalShow({ isOpen: false, children: null })} label=""
                children={modalShow.children}
                className={modalShow.className}
            />
            <div className='d-flex justify-content-between'>
                <div>
                    <Typography variant={"heading4"} margin={'mb-2'} label={"User Management"} />
                </div>
                <div onClick={handleCreateSubUserModal}>
                    {JSON.parse(localStorage.getItem("loginResponse"))?.role_id !== 5 ? <Button label="Create Sub User" variant="btn-link" /> : <></>}
                </div>
            </div>
            <Row>
                <Col xs={12}>
                    <Accordion variant="settings-usermanagement" options={options} activeKey={activeKey} />
                </Col>
            </Row>
        </div>
    )
})

const HeaderComponent = ({ eventKey, activeKey, setActiveKey, subUser, handleAccordionOpen, setSelectedSubUser, dispatch, showErrorMessage, showSuccessMessage }) => {
    const [isChecked, setIsChecked] = useState(Boolean(subUser?.is_active));

    const handleToggle = useAccordionButton(eventKey, () => {
        handleAccordionOpen(subUser)
        if (eventKey === activeKey)
            setActiveKey(-1);
        else setActiveKey(eventKey);
    });

    const handleSubUserStatus = async (e, id) => {
        setIsChecked(e.target.checked);
        const payload = { sub_user_data: [{ sub_user_id: id, is_active: e.target.checked, permissions: [] }] }
        const res = await dispatch(actionUpdateSubUserStatus(payload))
        if (res?.success) {
            showSuccessMessage(res?.message)
        } else {
            showErrorMessage(res?.message)
        }
    }
    const CollapsedView = () => (
        <>
            <Row>
                <Col xs={11} className={'flex-xl-fill'}>
                    <div className='d-flex justify-content-between flex-wrap'>
                        <div>
                            <Typography variant={"text-custom"} fontSize="text-16" fontWeight={'font-semibold'} color="color-gray03" margin="mb-0" label={subUser?.first_name} />
                        </div>
                        <div>
                            <Switch variant={"settings-handler"} name={`sub-user${subUser?.id}`} checked={Boolean(isChecked)} handleChange={(e) => handleSubUserStatus(e, subUser?.id)} label={isChecked ? "Active" : "Inactive"} />
                        </div>
                    </div>
                </Col>
                <Col xs={1} xl={'auto'} className={'text-end ps-0'}>
                    <span onClick={handleToggle} className='toggle-button-expanded'>
                        <CaretRightIcon />
                    </span>
                </Col>
            </Row>
        </>
    )

    const ExpandedView = () => (
        <>
            <Row>
                <Col xs={11} className={'flex-xl-fill'}>
                    <div className='d-flex justify-content-between flex-wrap'>
                        <div>
                            <Typography variant={"text-custom"} fontSize="text-16" fontWeight={'font-semibold'} color="color-gray03" margin="mb-0" label={subUser?.first_name} />
                        </div>
                        <div>
                            <Switch variant={"settings-handler"} name={`sub-user${subUser?.id}`} checked={Boolean(isChecked)} handleChange={(e) => handleSubUserStatus(e, subUser?.id)} label={isChecked ? "Active" : "Inactive"} />
                        </div>
                    </div>
                </Col>
                <Col xs={1} xl={'auto'} className={'text-end ps-0'}>
                    <span className='toggle-icon-collapsed'>
                        <CaretRightIcon onClick={handleToggle} />
                    </span>
                </Col>
            </Row>
        </>
    )
    return (
        <div className='custom-header'>
            {activeKey === eventKey ? <ExpandedView /> : <CollapsedView />}
        </div>
    )
}

const BodyComponent = ({ subUser, dispatch, showErrorMessage, showSuccessMessage }) => {
    const transformedArray = React.useMemo(() => ([
        { id: 1, label: "Can Place Order Request", checked: false },
        { id: 2, label: "Can Track My Order", checked: false },
        { id: 3, label: "Can Access Documents", checked: false },
        { id: 4, label: "Can Add To Cart", checked: false },
        { id: 5, label: "Can See Products", checked: false },
        { id: 6, label: "Can Edit My Profile", checked: false }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ]), [subUser])

    const handleSubUserPermissions = async (e, permissionId) => {
        const checked = e.target.checked;
        let permissions = [...subUser?.sub_user_permissions]
        if (checked) {
            permissions.push(permissionId);
        } else {
            permissions = permissions.filter(ids => ids !== permissionId)
        }
        const payload = { sub_user_data: [{ sub_user_id: subUser?.id, is_active: Boolean(subUser?.is_active), permissions: permissions }] }
        const res = await dispatch(actionUpdateSubUserStatus(payload))
        if (res?.success) {
            showSuccessMessage(res?.message)
        } else {
            showErrorMessage(res?.message)
        }
    }

    return (
        <div>
            {transformedArray.map((permission) => <div key={permission?.id} className='d-flex flex-row align-items-center mb-2 gap-2'>
                <Switch variant='settings-handler' name={`${subUser?.id}${permission?.id}`} handleChange={(e) => handleSubUserPermissions(e, permission?.id)}
                 label={subUser?.sub_user_permissions && subUser?.sub_user_permissions.includes(permission.id) ? "ON" : "OFF"} checked={subUser?.sub_user_permissions && subUser?.sub_user_permissions.includes(permission.id)} />
                <Typography variant={"text-custom"} fontSize="text-14" fontWeight={'font-semibold'} color="color-gray" margin="mb-0" label={permission?.label} />
            </div>)}
        </div>
    )
}