import React, { useState } from 'react'
import { Navbar, Placeholder } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { actionCheckOTP, actionForgotPassword } from '../../../actions/auth';
import { Avatar, Button, ButtonSpinner, Typography } from '../../../components';
import OTPInputCOmponent from '../../../components/OTP';
import { SnackMessages } from '../../../components/Toast';
import "./index.scss";

const CheckOTP = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading, user } = useSelector((state) => state.authReducer)
    const { showSuccessMessage, showErrorMessage } = SnackMessages();
    const [otp, setOtp] = useState('');


    const handleChangeOtp = (otp) => setOtp(otp)

    const handleSubmit = async () => {
         if (otp?.length === 4) {
            const payload = { otp: otp, key: user?.key }
            const response = await dispatch(actionCheckOTP(payload))
            if (response?.success) {
                showSuccessMessage(response.message);
                navigate('/auth/reset-password');
            }
            else showErrorMessage(response.message);

        } else {
            showErrorMessage('Please enter valid OTP');
        }
    }

    const handleRequestOTPAgain = async () => {
        const res = await dispatch(actionForgotPassword({ email: user?.email }))
        if (res.success) {
            // navigate('/auth/check-otp')
            showSuccessMessage(res.message);
        } else {
            showErrorMessage(res.message)
            navigate('/auth/login')
        }
    }

    return (
        <div className='check-otp'>
            <div className='row align-items-center justify-content-between my-lg-5'>
                <div className='col-12 col-md-5 d-flex justify-content-center align-items-center'>
                    <Navbar.Brand href="/auth/login">
                        <Link to="/"> <Avatar variant={'logo-login'} bsPrefix src="/images/logo-v.svg" /></Link>
                    </Navbar.Brand>
                </div>
                <div className='col-12 col-md-7 text-start mt-4 mt-md-0'>
                    <Typography variant={'title'} label="Verification Code" />
                    <Typography variant={'subtitle-v1'} margin="mb-3" label="We have sent the verification code to your email"
                        sublabel={user?.email} />

                    <OTPInputCOmponent otp={otp} numInputs={4} handleChange={handleChangeOtp} separator={<span className='mx-1'></span>} />
                    <div className='d-flex align-items-center justify-content-start pt-3 otp-button'>
                        <Button disabled={isLoading} onClick={handleSubmit} 
                        label={isLoading ? <ButtonSpinner label={'Submitting ...'} /> : "Submit"}
                         type="submit" variant="btn-primary-lg" />
                    </div>
                    {isLoading
                        ? (<CustomLoader />) :
                        (<div className='d-flex align-items-center mt-2'>
                            <Typography variant={'copyright'} label="Didn’t get the code?" color="color-gray02" />
                            <Button label=" Request again" onClick={handleRequestOTPAgain} variant="link-info" />
                        </div>)}
                </div>
            </div>
        </div>
    )
}


const CustomLoader = () => {
    return (
        <div className='mt-3'>
            <Placeholder animation="glow">
                <Placeholder xs={6} style={{ width: '100%', height: 25 }} />
            </Placeholder>
        </div>
    )
}

export default React.memo(CheckOTP);