import React from 'react'
import { Spinner } from 'react-bootstrap'
import { Avatars } from '../Avatars'
import "./index.scss"

export const GlobalSpinner = () => {
    return (
        <div className='spinners'>
            <Avatars src="/logo.gif" variant={"gif-loader"} />
        </div>
    )
}

export const ButtonSpinner = ({ label }) => {
    return (
        <div className='button-spinner gap-2'>
            <Spinner animation="border" size="sm" variant="secondary" role="status" />
            <span >{label}</span>
        </div>
    )
}