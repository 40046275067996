import React from 'react';
import { Typography } from '../Typography';
import StepperHorizontal from "react-stepper-horizontal";
import { ApprovalIcon, DeliveredIcon, ProcessingIcon, ShippedIcon, SoIcon } from '../SVGIcons';
import "./index.scss"

export const CustomStepper = ({ activeStep, variant }) => {

  const StepIconComponent = ({ icon, label }) => (
    <div role="button" className='d-flex flex-column gap-3 align-items-center justify-content-center m-auto rounded-4 shadow-card p-0 h-100 item'>
      <span className='stepper-label'><Typography variant={'text-custom'} margin="mb-0" fontSize="text-12 text-center" fontWeight="font-semibold" label={label} /></span>
      {icon}
    </div>)

  const steps = [
    { title: <StepIconComponent icon={<ApprovalIcon />} label={'Approval'} />, onClick: () => console.log('Step 1 clicked') },
    { title: <StepIconComponent icon={<SoIcon />} label={'MO'} />, onClick: () => console.log('Step 2 clicked') },
    { title: <StepIconComponent icon={<ProcessingIcon />} label={'Processing'} />, onClick: () => console.log('Step 3 clicked') },
    { title: <StepIconComponent icon={<ShippedIcon />} label={'Partially Shipped'} />, onClick: () => console.log('Step 4 clicked') },
    { title: <StepIconComponent icon={<DeliveredIcon />} label={'Shipped'} />, onClick: () => console.log('Step 5 clicked') },
  ];

  const renderStepper = () => {
    switch (variant) {
      case 'order-status':

        return (<div>
          <StepperHorizontal
            steps={steps}
            activeStep={activeStep}
            onStepChange={"setActiveStep"}
          />
        </div>)

      default:
        return (<StepperHorizontal
          startingStep={activeStep}
          steps={steps}
        />)
    }

  }


  return (
    <div className='stepper'>
      {renderStepper()}
    </div>
  )
}
