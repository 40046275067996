import React from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'


import './index.scss'

export const Buttons = ({ variant, icon, label, disabled, onClick, onSecondClick, pos, type, count, sublabel, spacing, size, className }) => {

    const renderButtonComponent = () => {
        switch (variant) {
            case 'btn-primary-lg':
                return <Button type={type} onClick={onClick} disabled={disabled} className={`btn-primary-lg ${className}`}>{icon && pos!=='right' ?  icon :''} {label }{icon && pos==='right' ?  <span>{icon}</span> :''} </Button>
            case 'btn-primary-md':
                return <Button  type={type} onClick={onClick} disabled={disabled} className="btn-primary-md">{icon && pos!=='right' ?  icon :''} {label }{icon && pos==='right' ?  <span>{icon}</span> :''} </Button>
            case 'link-info':
                return <Button type={type} onClick={onClick} className={`link-info ${className}`}>{icon && pos!=='right' ?  icon :''} {label }{icon && pos==='right' ?  <span>{icon}</span> :''} </Button>
            case 'link-secondary':
                return <Button type={type} onClick={onClick} className={`link-secondary ${className}`}>{icon && pos!=='right' ?  icon :''} {label }{icon && pos==='right' ?  <span>{icon}</span> :''} </Button>
            case 'primary-gray':
                return <Button type={type} onClick={onClick} disabled={disabled} variant="primary-gray" className={className}>{icon && pos!=='right' ?  icon :''} {label }{icon && pos==='right' ?  <span>{icon}</span> :''} </Button>
            case 'btn-link':
                return <Button type={type} onClick={onClick} disabled={disabled} variant="link" className={type}>{icon && pos!=='right' ?  icon :''} {label }{icon && pos==='right' ?  <span>{icon}</span> :''} </Button>
            case 'secondary':
                return <Button type={type} onClick={onClick} variant="secondary" className={size}>{icon && pos!=='right' ?  icon :''} {label }{icon && pos==='right' ?  <span>{icon}</span> :''} </Button>
            case 'primary-shadow':
                return <Button type={type} onClick={onClick} variant="primary-shadow" disabled={disabled} className={className}>{icon && pos!=='right' ?  icon :''}{count ? <span className='count'>{count}</span> :''} {label }{icon && pos==='right' ?  <span>{icon}</span> :''} </Button>
            case 'secondary-shadow':
                return <Button type={type}  onClick={onClick} variant="secondary-shadow" className={className}>{icon && pos!=='right' ?  icon :''} {label }{icon && pos==='right' ?  <span>{icon}</span> :''} </Button>
            case 'secondary-gray':
                return <Button type={type} onClick={onClick} disabled={disabled} variant="secondary-gray" className={className}>{icon && pos!=='right' ?  icon :''} {label }{icon && pos==='right' ?  <span>{icon}</span> :''} </Button>
            case 'info':
                return <Button type={type} onClick={onClick} variant="info" className={size}>{icon && pos!=='right' ?  icon :''} {label }{icon && pos==='right' ?  <span>{icon}</span> :''} </Button>
            case 'primary-rounded':
                return <Button type={type} onClick={onClick} variant="primary-rounded">{icon} {count ? <span className='count'>{count}</span> :''} </Button>
            case 'white-rounded':
                return <Button type={type} onClick={onClick} variant="white-rounded">{icon} {count ? <span className='count'>{count}</span> :''} </Button>
            case 'button-positive':
                return <Button type={type} onClick={onClick} variant="button-positive">{icon}</Button>

            case 'button-group':
                return <ButtonGroup aria-label="Filter Button">
                            <Button variant="secondary-shadow" disabled={disabled} className={spacing} onClick={onClick}>{icon}{label}</Button>
                            <Button variant="primary-shadow" disabled={disabled} className="px-1" onClick={onSecondClick}>{sublabel}</Button>
                        </ButtonGroup>

            case 'button-wishlist':
                return <Button type={type} disabled={disabled} onClick={onClick} variant="button-wishlist">{icon}</Button>

            case 'button-back':
                return <Button type={type} disabled={disabled} onClick={onClick} variant="button-back">{icon}</Button>

            default:
                return <Button type={type} onClick={onClick} disabled={disabled} variant="primary">{icon && pos!=='right' ?  icon :''} {label }{icon && pos==='right' ?  <span>{icon}</span> :''} </Button>
        }


    }


    return (
        <div className='Buttons'>

            {renderButtonComponent()}

        </div>
    )
}
