
import {
    LOGIN_FAILURE,
    LOGIN_BEGINS, LOGIN_SUCCESS, LOGOUT_BEGINS, LOGOUT_SUCCESS, LOGOUT_FAILURE, SEND_FORGOT_PASSWORD_BEGINS,
    SEND_FORGOT_PASSWORD_SUCCESS, SEND_FORGOT_PASSWORD_FAILURE, RESET_PASSWORD_BEGINS, RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    CHECK_OTP_BEGINS,
    CHECK_OTP_FAILURE,
    CHECK_OTP_SUCCESS,
    GET_USER_DETAILS_BEGINS,
    GET_USER_DETAILS_SUCCESS,
    GET_USER_DETAILS_FAILURE,
    ADD_NEW_ADDRESS_BEGINS,
    ADD_NEW_ADDRESS_SUCCESS,
    ADD_NEW_ADDRESS_FAILURE
} from '../../constants';
import api from '../../utils/axios';


const loginBegins = () => ({ type: LOGIN_BEGINS })

const loginSuccess = (data) => ({ type: LOGIN_SUCCESS, data })

const loginFails = () => ({ type: LOGIN_FAILURE })

const logoutBegins = () => ({ type: LOGOUT_BEGINS })

const logoutSuccess = () => ({ type: LOGOUT_SUCCESS })

const logoutFails = () => ({ type: LOGOUT_FAILURE })

const getUserDetailsBegins = () => ({ type: GET_USER_DETAILS_BEGINS })

const getUserDetailsSuccess = (data) => ({ type: GET_USER_DETAILS_SUCCESS, data })

const getUserDetailsFailure = () => ({ type: GET_USER_DETAILS_FAILURE })

const forgotPasswordBegins = () => ({ type: SEND_FORGOT_PASSWORD_BEGINS })

const forgotPasswordSuccess = (data) => ({ type: SEND_FORGOT_PASSWORD_SUCCESS, data })

const forgotPasswordFails = () => ({ type: SEND_FORGOT_PASSWORD_FAILURE })

const resetPasswordBegins = () => ({ type: RESET_PASSWORD_BEGINS })

const resetPasswordSuccess = (data) => ({ type: RESET_PASSWORD_SUCCESS, data })

const resetPasswordFails = () => ({ type: RESET_PASSWORD_FAILURE })

const checkOTPBegins = () => ({ type: CHECK_OTP_BEGINS })

const checkOTPSuccess = () => ({ type: CHECK_OTP_SUCCESS })

const checkOTPFailure = () => ({ type: CHECK_OTP_FAILURE })

const addnewAdressBegins = () => ({ type: ADD_NEW_ADDRESS_BEGINS })

const addnewAddressSuccess = (data) => ({ type: ADD_NEW_ADDRESS_SUCCESS, data })

const addndewAddressFailure = () => ({ type: ADD_NEW_ADDRESS_FAILURE })

export const actionGetUserDetails = () => async (dispatch) => {
    dispatch(getUserDetailsBegins())
    try {
        const response = await api.get('/user-details-by-access-token')
        const { data } = response?.data
        dispatch(getUserDetailsSuccess(data))
        return { success: true };

    } catch (error) {
        dispatch(getUserDetailsFailure())
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        return { success: false, message };
    }
}

export const actionLogin = (data) => async (dispatch) => {
    dispatch(loginBegins());
    try {
        const res = await api.post('/customer-login', data);
        if (res.status === 200) {
            const { message, access_token, first_login } = res?.data || { message: 'Loggedin successfully' };
            api.defaults.headers.common["Authorization"] = `Bearer ${access_token}`
            localStorage.setItem('logged-in', JSON.stringify({ token: access_token, first_login, email: data.email }));
            dispatch(loginSuccess({ email: data.email, access_token, first_login, key: access_token }));
            return { success: true, message: message, access_token, first_login, data: res?.data };
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(loginFails())
        return { success: false, message: message }
    }
}

export const actionLogout = () => async (dispatch) => {

    dispatch(logoutBegins());
    try {
        const res = await api.post('/logout')
        if (res.status === 200) {
            const { message } = res?.data || { message: 'Logout Successful' };
            dispatch(logoutSuccess())
            return { success: true, message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went wrong' }
        dispatch(logoutFails())
        return { success: false, message }
    }
}

export const actionForgotPassword = (data) => async (dispatch) => {
    dispatch(forgotPasswordBegins());
    try {
        const res = await api.post('/customer/forget-password', data);
        if (res.status === 200) {
            const { message, key } = res?.data || { message: 'OTP Sended to Your Mail' };
            dispatch(forgotPasswordSuccess({ key, email: data.email }));
            return { success: true, message: message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(forgotPasswordFails())
        return { success: false, message: message }
    }
}

export const resetPassword = (url, data) => async (dispatch) => {
    dispatch(resetPasswordBegins());
    try {
        const res = await api.post(url, data);
        if (res.status === 200) {
            const { message, token } = res?.data || { message: 'Password Changed Successfully' };
            const localStorageData = { ...JSON.parse(localStorage.getItem('logged-in')), access_token: token, first_login: 0 };
            localStorage.removeItem('logged-in');
            localStorage.setItem('logged-in', JSON.stringify(localStorageData));
            dispatch(resetPasswordSuccess(localStorageData))
            return { success: true, message: message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(resetPasswordFails())
        return { success: false, message: message }
    }
}

export const actionCheckOTP = (data) => async (dispatch) => {
    dispatch(checkOTPBegins());
    try {
        const res = await api.post('/check-otp', data);
        if (res.status === 200) {
            const { message } = res?.data || { message: 'OTP Verified' };
            dispatch(checkOTPSuccess())
            return { success: true, message: message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(checkOTPFailure())
        return { success: false, message: message }
    }
}

export const actionAddNewAddress = (payload) => async (dispatch) => {
    dispatch(addnewAdressBegins());
    try {
        const res = await api.post('/customer/add-address', payload);
        if (res.status === 200) {
            const { message, data } = res?.data || { message: 'Address Added Successfully' };

            dispatch(addnewAddressSuccess())
            return { success: true, message: message, data }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(addndewAddressFailure())
        return { success: false, message: message }
    }
}