import {
    ADD_NEW_UNIT_BEGINS,
    ADD_NEW_UNIT_FAILURE,
    ADD_NEW_UNIT_SUCCESS,
    ADD_OR_REMOVE_PRODUCTS_FROM_CART_BEGINS,
    ADD_OR_REMOVE_PRODUCTS_FROM_CART_FAILURE,
    ADD_OR_REMOVE_PRODUCTS_FROM_CART_SUCCESS,
    CLEAR_CART_LIST_SUCCESS,
    DELETE_CUSTOM_UNIT_BEGINS,
    DELETE_CUSTOM_UNIT_FAILURE,
    DELETE_CUSTOM_UNIT_SUCCESS,
    GET_CART_LIST_BEGINS, GET_CART_LIST_FAILURE, GET_CART_LIST_SUCCESS, GET_UNIT_LIST_BEGINS, GET_UNIT_LIST_FAILURE, GET_UNIT_LIST_SUCCESS, PLACE_ORDER_BEGINS, PLACE_ORDER_FAILURE, PLACE_ORDER_SUCCESS, UPDATE_CART_ITEMS_COUNT_SUCCESS, UPDATE_CART_ITEM_BEGINS, UPDATE_CART_ITEM_FAILURE,
    UPDATE_CART_ITEM_SUCCESS,
    UPDATE_PRODUCT_DESCRIPTION_BEGINS,
    UPDATE_PRODUCT_DESCRIPTION_FAILURE,
    UPDATE_PRODUCT_DESCRIPTION_SUCCESS
} from "../../constants";
import api from "../../utils/axios";

const getCartListBegins = () => ({ type: GET_CART_LIST_BEGINS });

const getCartListSuccess = (data) => ({ type: GET_CART_LIST_SUCCESS, data });

const getCartListFailure = () => ({ type: GET_CART_LIST_FAILURE });

const updateCartItemBegins = () => ({ type: UPDATE_CART_ITEM_BEGINS });

const updateCartItemSuccess = () => ({ type: UPDATE_CART_ITEM_SUCCESS });

const updateCartItemFails = () => ({ type: UPDATE_CART_ITEM_FAILURE });

const placeorderBegins = () => ({ type: PLACE_ORDER_BEGINS });

const placeOrderSuccess = (data) => ({ type: PLACE_ORDER_SUCCESS, data });

const placeOrderFails = () => ({ type: PLACE_ORDER_FAILURE });

const addOrRemoveProductFromCartBegins = () => ({ type: ADD_OR_REMOVE_PRODUCTS_FROM_CART_BEGINS });

const addOrRemoveProductFromCartSuccess = (data) => ({ type: ADD_OR_REMOVE_PRODUCTS_FROM_CART_SUCCESS, data });

const addorRemoveProductFromCartFails = () => ({ type: ADD_OR_REMOVE_PRODUCTS_FROM_CART_FAILURE });

const updateProductDescriptionBegins = () => ({ type: UPDATE_PRODUCT_DESCRIPTION_BEGINS });

const updateProductDescriptionSuccess = (data) => ({ type: UPDATE_PRODUCT_DESCRIPTION_SUCCESS, data });

const updateProductDescriptionFails = () => ({ type: UPDATE_PRODUCT_DESCRIPTION_FAILURE });

const clearCartList = () => ({ type: CLEAR_CART_LIST_SUCCESS });

const updateCartCount = (data) => ({ type: UPDATE_CART_ITEMS_COUNT_SUCCESS, data });

const addNewUnitBegins = () => ({ type: ADD_NEW_UNIT_BEGINS });

const addNewUnitSuccess = () => ({ type: ADD_NEW_UNIT_SUCCESS });

const addNewUnitFails = () => ({ type: ADD_NEW_UNIT_FAILURE });

const getUnitListBegins = () => ({ type: GET_UNIT_LIST_BEGINS });

const getUnitListSuccess = (data) => ({ type: GET_UNIT_LIST_SUCCESS, data });

const getUnitListFails = () => ({ type: GET_UNIT_LIST_FAILURE });

const deleteCustomUnitBegins = () => ({ type: DELETE_CUSTOM_UNIT_BEGINS });

const deleteCustomUnitSuccess = () => ({ type: DELETE_CUSTOM_UNIT_SUCCESS });

const deleteCustomUnitFails = () => ({ type: DELETE_CUSTOM_UNIT_FAILURE });


//GET CART LIST
export const actionGetCartLists = ({ page, limit }) => async (dispatch) => {
    const checkQuantityExist = (id, quantities) => {
        const staticArray = [
            { cart_id: id, unit: null, quantity: null, slab: "2cm" },
            { cart_id: id, unit: null, quantity: null, slab: "3cm" },
            { cart_id: id, unit: null, quantity: null, slab: "4cm" },
        ]
        const selected = quantities?.length && quantities?.filter(quantity => quantity?.quantity);
        const results = staticArray.filter(({ slab: id1 }) => !selected.some(({ slab: id2 }) => id2 === id1));
        return [...results, ...selected];
    };

    dispatch(getCartListBegins());
    try {
        const res = await api.get("/customer/my-cart", { params: { limit, offset: page } })
        if (res.status === 200) {
            const newData = res?.data?.data?.data || [];
            const response = {
                data: newData?.map((item) => ({
                    ...item,
                    itemQuantity: "",
                    quantity: item?.quantity || "",
                    errorMessage: "",
                    quantities:
                        item?.material === "Slabs"
                            ? item.quantities && item.quantities.length === 3
                                ? item.quantities
                                : checkQuantityExist(item.id, item?.quantities)
                            : item?.quantities,
                })),
                total: res?.data?.data?.total || 0,
            };
            dispatch(getCartListSuccess(response));
            return { success: true, message: "success" };
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: "Something went Wrong" };
        dispatch(getCartListFailure());
        return { success: false, message: message };
    }
};

// UPDATE CART ITEM
export const actionupdateCartLists = (data) => async (dispatch) => {
    dispatch(updateCartItemBegins());
    try {
        const res = await api.put('/customer/cart-update', data);
        if (res.status === 200) {
            const { message } = res?.data || { message: 'Cart updated successfully' }
            dispatch(updateCartItemSuccess());
            return { success: true, message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(updateCartItemFails());
        return { success: false, message: message }
    }
}

// PLACE ORDER
export const actionplaceOrder = (payload) => async (dispatch) => {
    dispatch(placeorderBegins());
    try {
        const res = await api.post('/customer/order-request', payload);
        if (res.status === 200) {
            const { message, data } = res?.data || { message: 'Order placed successfully' }
            dispatch(placeOrderSuccess(data));
            return { success: true, message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(placeOrderFails());
        return { success: false, message: message }
    }
}

// ADD OR REMOVE PRODUCTS FROM CART
export const actionAddOrRemoveProductFromCart = (payload) => async (dispatch) => {
    dispatch(addOrRemoveProductFromCartBegins());
    try {
        const res = await api.post('/customer/add-to-cart', payload);
        if (res.status === 200) {
            const { message } = res?.data || { message: 'Cart updated successfully' }
            dispatch(addOrRemoveProductFromCartSuccess());
            return { success: true, message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(addorRemoveProductFromCartFails());
        return { success: false, message: message }
    }
}

// UPDATE PRODUCT DESCRIPTION
export const actionUpdateProductDescription = (payload) => async (dispatch) => {
    dispatch(updateProductDescriptionBegins());
    try {
        const res = await api.put('/customer/cart/update-product-description', payload);
        if (res.status === 200) {
            const { message } = res?.data || { message: 'Product description updated successfully' }
            dispatch(updateProductDescriptionSuccess());
            return { success: true, message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(updateProductDescriptionFails());
        return { success: false, message: message }
    }
}

// CLEAR CART LIST
export const actionClearCartList = () => (dispatch) => dispatch(clearCartList())

// UPDATE CART COUNT
export const actionUpdateCartCount = (data) => async (dispatch) => dispatch(updateCartCount(data))

// ADD NEW UNIT
export const actionAddNewUnit = (payload) => async (dispatch) => {
    dispatch(addNewUnitBegins());
    try {
        const res = await api.post('/customer/cart/product-unit', payload);
        if (res.status === 200) {
            const { message, data } = res?.data || { message: 'Unit added successfully' }
            dispatch(addNewUnitSuccess());
            return { success: true, message, data }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(addNewUnitFails());
        return { success: false, message: message }
    }
}

export const actionGetUnitList = () => async (dispatch) => {
    dispatch(getUnitListBegins());
    try {
        const res = await api.get('/customer/custom-product-units');
        if (res.status === 200) {
            const { message, data } = res?.data;
            dispatch(getUnitListSuccess(data));
            return { success: true, message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(getUnitListFails());
        return { success: false, message: message }
    }
}

export const deleteCustomUnit = ({ id }) => async (dispatch) => {
    dispatch(deleteCustomUnitBegins());
    try {
        const res = await api.delete(`/customer/delete-custom-product-unit?id=${id}`);
        if (res.status === 200) {
            const { message } = res?.data || { message: 'Unit deleted successfully' }
            dispatch(deleteCustomUnitSuccess());
            return { success: true, message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(deleteCustomUnitFails());
        return { success: false, message: message }
    }
}